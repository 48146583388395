import React from "react";

import styles from "./Badge.module.css";

const Badge = (props) => {
  return (
    <div className={styles.badge}>
      {props.children}
      <button onClick={props.handleRemove} className={styles.remove}>
        &times;
      </button>
    </div>
  );
};

export default Badge;
