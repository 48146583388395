import React from "react";
import cn from "classnames";
import Autocomplete from "react-google-autocomplete";
import getConfig from "next/config";
import {parseMovingAddress} from "@/util/helperFunctions";
import styles from "./PlaceAutocompleteInput.module.css";

const PlaceAutocompleteInput = ({
  form,
  control,
  className,
  error,
  onChange,
  value,
  onPlaceSelect,
  placeholder,
  onFocus,
  name,
  PostalCodeSearch,
  ...props
}) => {
  const {
    publicRuntimeConfig: {placesApiKey},
  } = getConfig();

  const handleSelectPlace = async (place) => {
    console.log("%c place", "color: red; font-size: 16px;", place);
    const addressArray = place.address_components || [];
    const formattedAddress = place.formatted_address || "";
    if (addressArray.length > 0) {
      const streetNumberObject = addressArray.find((obj) => obj.types.includes("street_number"));
      const aptNumberObject = addressArray.find((obj) => obj.types.includes("subpremise"));

      const streetAddressObject = addressArray.find((obj) => obj.types.includes("route"));
      let cityObject = addressArray.find((obj) => obj.types.includes("locality"));
      if (!cityObject || !cityObject.long_name) {
        cityObject = addressArray.find((obj) => obj.types.includes("postal_town"));
      }
      if (!cityObject || !cityObject.long_name) {
        cityObject = addressArray.find((obj) => obj.types.includes("administrative_area_level_3"));
      }
      const stateObject = addressArray.find((obj) =>
        obj.types.includes("administrative_area_level_1")
      );
      const countryObject = addressArray.find((obj) => obj.types.includes("country"));
      const postalCodeObject = addressArray.find((obj) => obj.types.includes("postal_code"));

      const streetNumber = streetNumberObject?.long_name || "";
      const aptNumber = aptNumberObject?.long_name || "";
      const streetAddress = streetAddressObject?.long_name || "";
      const city = cityObject?.long_name || "";
      const state = stateObject?.long_name || "";
      const country = countryObject?.long_name || "";
      const countryCode = countryObject?.short_name || "";
      const zipcode = postalCodeObject?.long_name || "";

      const lat = parseFloat(place.geometry?.location?.lat()).toFixed(8);
      const lng = parseFloat(place.geometry?.location?.lng()).toFixed(8);

      const numberFirstFormat =
        countryCode === "US" ||
        countryCode === "CA" ||
        formattedAddress.includes(`${streetNumber} ${aptNumber} ${streetAddress}`);

      let address1;
      if (streetAddress && streetNumber) {
        address1 = numberFirstFormat
          ? `${streetNumber} ${aptNumber} ${streetAddress}`
          : `${streetAddress} ${streetNumber} ${aptNumber}`;
      } else {
        address1 = streetAddress;
      }
      let origin = parseMovingAddress(address1, city, state, zipcode, countryCode);
      let addressDetails = {
        country_code: countryCode,
        address1: address1,
        state: state,
        city: city,
        zipcode: zipcode,
        lat: lat,
        lng: lng,
        origin: origin,
      };
      onPlaceSelect?.(addressDetails);
    }
  };

  return (
    <>
      <Autocomplete
        apiKey={placesApiKey}
        onPlaceSelected={(place) => handleSelectPlace(place)}
        className={cn(
          styles.input,
          {
            [styles.error]: error,
          },
          className
        )}
        ref={form}
        onChange={onChange}
        control={control}
        placeholder={placeholder}
        options={{
          types: PostalCodeSearch ? ["postal_code"] : ["geocode"],
        }}
        defaultValue={value}
        onFocus={(e) => {
          onFocus?.(e);
        }}
        name={name}
        {...props}
      />
      <input
        style={{display: "none"}}
        ref={form}
        onFocus={onFocus}
        onChange={onChange}
        control={control}
        defaultValue={value}
        name={name}
        autoComplete="off"
        {...props}
      />
    </>
  );
};

export default PlaceAutocompleteInput;
