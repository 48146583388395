import React from "react";
import cn from "classnames";

import styles from "./TaskFilterButton.module.css";

import {Icon} from "UI";

const TaskFilterButton = ({onClick, className}) => (
  <button className={cn(styles["filter-button"], className)} onClick={onClick}>
    <div className={styles["filter-button-content"]}>
      Filter: All
      <div className={styles["filter-container"]}>
        <Icon icon="filter" className={styles["filter-mark"]} />
      </div>
    </div>
  </button>
);

export default TaskFilterButton;
