import React from "react";
import cn from "classnames";

import {Icon} from "UI";

import styles from "./IconButton.module.css";

export default function IconButton({
  icon,
  color,
  children,
  onClick,
  className,
  isLarge = false,
  isHoverPointer = false,
  ...rest
}) {
  return (
    <button type="button" className={cn(styles.button, className)} onClick={onClick} {...rest}>
      <Icon
        icon={icon}
        className={styles.icon}
        color={color}
        isLarge={isLarge}
        isHoverPointer={isHoverPointer}
      />
      {children}
    </button>
  );
}
