import React, {useState, useEffect} from "react";
import styles from "./FormImageInput.module.css";

const FormImageInput = (props) => {
  const {form, label, name, getValues, header, defaultValue, company} = props;
  const [src, setSrc] = useState(null);
  const [imageName, setImageName] = useState(null);

  useEffect(() => {
    if (defaultValue && defaultValue[0]) {
      setImageName(company ? "Company image" : "Profile image");
      setSrc(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = () => {
    try {
      let picture = getValues(`${name}`);
      if (picture[0].name) {
        setImageName(picture[0].name.split(".")[0]);
        setSrc(URL.createObjectURL(picture[0]));
      }
    } catch (err) {
      alert("Something went wrong");
    }
  };

  const shortenString = (inputString, maxLength) =>
    inputString.length <= maxLength ? inputString : inputString.substring(0, maxLength) + "...";

  return (
    <>
      {header && <label className={styles.header}>{header}</label>}
      <div className={styles["input-box"]}>
        <input
          onChange={handleChange}
          id={name}
          name={name}
          ref={form ? form() : null}
          type="file"
          accept="image/*"
        />
        <label htmlFor={name} className={styles["label-container"]}>
          <div className="d-flex align-items-center" style={{cursor: "pointer"}}>
            <div
              style={src ? {backgroundImage: `url("${src}")`} : null}
              className={styles["upload-circle"]}
            >
              {!src && <span>&#43;</span>}
            </div>
            <span className={styles.label}>
              {src === null ? label : shortenString(imageName, 20)}
            </span>
          </div>
        </label>
      </div>
    </>
  );
};

export default FormImageInput;
