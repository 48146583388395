import {useEffect} from "react";

export default function useOutsideAlerter(refs = [], isOpen, setIsOpen) {
  function handleClickOutside(event) {
    if (
      isOpen &&
      refs?.length &&
      refs.every((ref) =>
        typeof ref === "string"
          ? !event.target.classList.contains(ref)
          : !ref?.current?.contains(event.target)
      )
    ) {
      setIsOpen(false);
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}
