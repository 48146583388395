import React, {useEffect} from "react";

import styles from "./Toggler.module.css";
import cn from "classnames";

const Toggler = (props) => {
  const {active, setActive} = props;
  useEffect(() => {}, [active]);
  return (
    <div className={styles["circle-container"]}>
      <div
        onClick={() => setActive(0)}
        className={cn(styles["circle"], active == 0 ? styles["active"] : null)}
      ></div>

      <div
        onClick={() => setActive(1)}
        className={cn(styles["circle"], active == 0 ? null : styles["active"])}
      ></div>
    </div>
  );
};

export default Toggler;
