import {getApiClient} from "@/api/util";

export default (ctx) => ({
  getConnection: (company_id) => getApiClient(ctx).get(`/company/${company_id}/twilio-connect/`),
  connect: (company_id, data) =>
    getApiClient(ctx).post(`/company/${company_id}/twilio-connect/`, data),
  deleteConnection: (company_id) =>
    getApiClient(ctx).delete(`/company/${company_id}/twilio-connect/`),
  getPhoneNumbers: (company_id) => getApiClient(ctx).get(`/company/${company_id}/phone-numbers/`),
  setDefaultPhoneNumber: (company_id, phone_id) =>
    getApiClient(ctx).put(`/company/${company_id}/phone-numbers/${phone_id}/`),
});
