import React from "react";
import cn from "classnames";
import Link from "next/link";
import {useRouter} from "next/router";

const ActiveLink = ({href, activeClassName, children, className}) => {
  const router = useRouter();
  return (
    <Link
      passHref={true}
      href={href}
      className={cn(className, {[activeClassName]: router.pathname === href && activeClassName})}
    >
      {children}
    </Link>
  );
};

export default ActiveLink;
