import React from "react";

import cn from "classnames";
import ReactDropdown from "react-dropdown";
import {Icon} from "UI";

import styles from "./Dropdown.module.css";

const Dropdown = ({
  disabled,
  value,
  options = [],
  className,
  controlClassName,
  placeholderClassName,
  menuClassName,
  iconClassName,
  onChange,
}) => {
  return (
    <ReactDropdown
      disabled={disabled}
      value={value}
      options={options}
      className={cn(styles.dropdown, {[styles.disabled]: disabled}, className)}
      controlClassName={cn(styles.control, controlClassName)}
      placeholderClassName={cn(styles.placeholder, placeholderClassName)}
      menuClassName={cn(styles.menu, menuClassName)}
      arrowClosed={<Icon icon="arrow-down" className={cn(styles.arrowDown, iconClassName)}></Icon>}
      arrowOpen={<Icon icon="arrow-down" className={cn(styles.arrowUp, iconClassName)}></Icon>}
      onChange={onChange}
    />
  );
};

export default Dropdown;
