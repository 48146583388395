import React from "react";
import moment from "moment";
import DayPicker, {DateUtils} from "react-day-picker";

import Head from "next/head";

import {IconButton} from "UI";

import styles from "./DateRangePicker.module.css";
import {DateRangePickerStyles} from "./DateRangePicker.styles";
import cn from "classnames";

const Navbar = (props) => {
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={props.removeBox ? {padding: "0 16px 0 16px"} : {padding: "16px"}}
    >
      <IconButton
        onClick={() => props.onPreviousClick()}
        icon="close-nav"
        style={{
          lineHeight: 0,
        }}
      />
      <p className={styles["date"]}>{moment(props.month).format("MMM YYYY")}</p>
      <IconButton
        onClick={() => props.onNextClick()}
        icon="close-nav"
        style={{
          lineHeight: 0,
          transform: "rotate(180deg)",
        }}
      />
    </div>
  );
};

const Caption = (_) => {
  return null;
};

const DateRangePicker = (props) => {
  let {showPicker, removeBox, dateRange, setDateRange, className} = props;

  const dateRangeTransformed = {
    from: typeof dateRange.from === "string" ? new Date(dateRange.from) : dateRange.from,
    to: typeof dateRange.to === "string" ? new Date(dateRange.to) : dateRange.to,
  };

  const handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, dateRangeTransformed);
    console.log({day, range});
    setDateRange(range);
  };

  const modifiers = {
    start: dateRangeTransformed.from,
    end: dateRangeTransformed.to,
  };

  return (
    <>
      <Head>
        <style>{DateRangePickerStyles}</style>
        {removeBox && (
          <style>{`
            .DayPicker{
              outline: none;
              background: transparent;
              height: auto;
              border-radius: 0;
              box-shadow: none;
            }
          `}</style>
        )}
      </Head>
      <div
        className={cn(styles["input-box"], className)}
        style={!showPicker ? {display: "none"} : {}}
      >
        {removeBox && <p className={styles.title}>Pick Date</p>}
        <DayPicker
          firstDayOfWeek={1}
          showOutsideDays
          captionElement={<Caption />}
          navbarElement={<Navbar removeBox />}
          onDayClick={handleDayClick}
          todayButton={<span className={styles["today"]}>Go to today</span>}
          onTodayButtonClick={() => handleDayClick(new Date())}
          selectedDays={[dateRangeTransformed.from, dateRangeTransformed]}
          modifiers={modifiers}
        />
        <div></div>
      </div>
    </>
  );
};

export default DateRangePicker;
