import React from "react";
import {
  FormTextInput,
  FormTextAreaInput,
  FormNumberInput,
  FormPlacesInput,
  FormImageInput,
  FormSelectInput,
  FormDateInput,
  FormDateRangeInput,
  FormTimeInput,
  FormDateTimeInput,
  FormRadioInput,
  FormFileInput,
  FormMultiSelectDropdown,
  FormSwitch,
  Checkbox,
} from "UI";

const InputField = ({fieldType, ...rest}) => {
  const fields = {
    time: <FormTimeInput {...rest} />,
    date: <FormDateInput {...rest} />,
    dateRange: <FormDateRangeInput {...rest} />,
    file: <FormFileInput {...rest} />,
    dateTime: <FormDateTimeInput {...rest} />,
    password: <FormTextInput {...rest} />,
    email: <FormTextInput {...rest} />,
    text: <FormTextInput {...rest} />,
    place: <FormPlacesInput {...rest} />,
    number: <FormNumberInput {...rest} />,
    textArea: <FormTextAreaInput {...rest} />,
    image: <FormImageInput {...rest} />,
    select: <FormSelectInput {...rest} />,
    radio: <FormRadioInput {...rest} />,
    selectMultiDropdown: <FormMultiSelectDropdown {...rest} />,
    switch: <FormSwitch {...rest} />,
    checkbox: <Checkbox {...rest} />,
  };
  return fields[fieldType];
};

export default InputField;
