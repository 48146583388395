import {getApiClient} from "@/api/util";

export default (ctx) => ({
  createOffice: (company_id, office) =>
    getApiClient(ctx).post(`/company/${company_id}/office/`, office),
  getOffices: (company_id) => getApiClient(ctx).get(`/company/${company_id}/office/`),
  editOffice: (company_id, office_id, editedOffice) =>
    getApiClient(ctx).put(`/company/${company_id}/office/${office_id}/`, editedOffice),
  deleteOffice: (company_id, office_id) =>
    getApiClient(ctx).delete(`/company/${company_id}/office/${office_id}/`),
});
