import React from "react";
import cn from "classnames";

import styles from "./Table.module.css";

const Wrapper = ({children, className}) => {
  return (
    <div className={cn(styles.wrapper, className)}>
      <table className={cn(styles.table, className)}>{children}</table>
    </div>
  );
};

export const Body = ({children}) => {
  return <tbody>{children}</tbody>;
};

export const Heading = ({children, className}) => {
  return <th className={cn(styles.th, className)}>{children || "Unavailable"}</th>;
};

export const Row = ({children, className, onClick, title, style}) => {
  return (
    <tr
      className={cn(styles.tr, className)}
      onClick={onClick && onClick}
      title={title}
      style={style}
    >
      {children}
    </tr>
  );
};

export const Data = ({children, className, onClick, isPopup, title, colSpan}) => {
  return (
    <td
      title={title}
      className={cn(styles.td, className, {[styles["td-popup"]]: isPopup})}
      onClick={onClick}
      colSpan={colSpan}
    >
      {children || "Unavailable"}
    </td>
  );
};

export const Empty = ({text}) => {
  return <div className={styles["empty-container"]}>{text || "No available data"}</div>;
};

export default {Wrapper, Heading, Body, Row, Data, Empty};
