import React from "react";

import TextareaAutosize from "react-textarea-autosize";
import cn from "classnames";

import styles from "./TextAreaInput.module.css";

const TextAreaInput = ({form, className, options, ...props}) => {
  return (
    <TextareaAutosize
      {...options}
      minRows={options ? options.minRows : 1}
      maxRows={options ? options.maxRows : 3}
      className={cn(styles.input, className)}
      ref={form}
      {...props}
    />
  );
};

export default TextAreaInput;
