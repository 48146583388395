import {getApiClient} from "@/api/util";
import axios from "axios";

export default (ctx) => ({
  getPresignedUrl: (company_id, lead_id, upload_type, file_name) =>
    getApiClient(ctx).post(`/company/${company_id}/leads/${lead_id}/upload/${upload_type}/`, {
      file_name,
    }),

  getUploadUrl: (data) => getApiClient(ctx).post(`/presigned-url/`, data),
  getPresignedUrlFromHash: (company_id, lead_id, upload_type, file_name) =>
    getApiClient(ctx).post(`/company/${company_id}/leads/${lead_id}/upload-hash/${upload_type}/`, {
      file_name,
    }),
  uploadFile: (file, url, fields, progress) => {
    let formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append("file", file);
    return axios.post(url, formData, progress);
  },
  verifyUpload: (company_id, file_name, hash) =>
    getApiClient(ctx).post(`/company/${company_id}/leads/upload/verify/`, {file_name, hash}),
  verifyUploadFromHash: (company_id, file_name, hash) =>
    getApiClient(ctx).post(`/company/${company_id}/leads/upload-hash/verify/`, {file_name, hash}),
});
