import React, {useEffect, useState} from "react";
import getConfig from "next/config";
import {useJsApiLoader, GoogleMap, DirectionsRenderer, Marker} from "@react-google-maps/api";
import {mapStyles} from "./mapStyles";

const GoogleMapUI = (props) => {
  const {
    publicRuntimeConfig: {placesApiKey},
  } = getConfig();

  const [libraries] = useState(["places"]);

  const {isLoaded} = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: placesApiKey,
    libraries,
  });

  const [directions, setDirections] = useState();

  const units = [
    [1, " s"],
    [60, " m"],
    [60 * 60, " h"],
    [60 * 60 * 24, " day"],
  ];

  const displayTime = (seconds) => {
    let bestUnit = units[0];
    for (const unit of units) {
      if (seconds >= unit[0]) {
        bestUnit = unit;
      }
    }
    const [divisor, label] = bestUnit;
    return Math.floor(seconds / divisor) + label;
  };

  const displayLength = (meters) => {
    const miles = (meters * 0.000621371).toFixed(1) + " mi";
    const km = (meters / 1000).toFixed(1) + " km";
    if (props.isMetricSystem) {
      return `${km} (${miles})`;
    } else {
      return `${miles} (${km})`;
    }
  };

  useEffect(() => {
    if (isLoaded) {
      const DirectionsService = new google.maps.DirectionsService();
      const {moving_from, moving_to} = props.moveData.coordinates;
      const waypoints = props.moveData.extraStops?.map((el) => ({
        location: new google.maps.LatLng(el.lat, el.lng),
        stopover: true,
      }));
      DirectionsService.route(
        {
          origin: new google.maps.LatLng(moving_from.lat, moving_from.lng),
          destination: new google.maps.LatLng(moving_to.lat, moving_to.lng),
          waypoints,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(response);
            let total_distance = 0;
            let total_duration = 0;
            for (var i = 0; i < response.routes[0].legs.length; i++) {
              total_distance += response.routes[0].legs[i].distance.value;
              total_duration += response.routes[0].legs[i].duration.value;
            }
            props.setDistance?.(displayLength(total_distance));
            props.setDuration?.(displayTime(total_duration));
          } else {
            console.error(`error fetching directions ${response}`);
          }
        }
      );
    }
  }, [isLoaded, props.moveData]);

  const mapOptions = {
    styles: mapStyles,
    disableDefaultUI: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    clickableIcons: true,
    scrollwheel: false,
    draggable: true,
  };

  const directionsOptions = {
    polylineOptions: {
      strokeColor: "#4761F9",
      strokeWeight: 6,
    },
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  return isLoaded ? (
    <GoogleMap zoom={9} options={mapOptions} mapContainerStyle={containerStyle}>
      {props.mapLocation && <Marker position={props.mapLocation} />}
      {!props.mapLocation && directions && (
        <DirectionsRenderer directions={directions} options={directionsOptions} />
      )}
    </GoogleMap>
  ) : null;
};

export default GoogleMapUI;
