import React from "react";
import cn from "classnames";

import styles from "./TransparentButton.module.css";

export default function TransparentButton({className, ...props}) {
  return (
    <button className={cn(styles.button, className)} {...props}>
      {props.children}
    </button>
  );
}
