import React from "react";
import cn from "classnames";
import styles from "./BackButton.module.css";

import {Icon} from "UI";

const BackButton = (props) => {
  const {children, onClick, className} = props;

  return (
    <button onClick={onClick} className={cn(styles.btn, className)}>
      <Icon icon="arrow-left" /> <span>{children}</span>
    </button>
  );
};

export default BackButton;
