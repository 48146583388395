import {setUser} from "@/redux/actions/user";
import cookies from "next-cookies";
import cn from "classnames";
import {setLeadSources, setMoveTypes, setLeadStages, setTaskTypes} from "@/redux/actions/types";
import {setCurrentCompany, setMembership} from "@/redux/actions/company";
import {setPlans} from "@/redux/actions/plans";
import authApi from "@/api/auth/auth";
import companyApi from "@/api/company/company";
import leadsApi from "@/api/leads/leads";
import paymentApi from "@/api/payment/payment";
import {useSelector} from "react-redux";
import Router from "next/router";

import {getProp} from "@kemoke/data-utils";
import {getSafeDeep} from "@/util/helperFunctions";
import {Icon} from "@/components/UI";

import styles from "./permissionGuard.module.css";

const reduxStorePreloadItem = (name, api, reducer) => ({name, api, reducer});
const reduxStorePreloadItems = [
  reduxStorePreloadItem(
    "types.leadSources",
    (ctx) => leadsApi(ctx)?.leadSources(ctx.reduxStore?.getState()?.company?.current?.id),
    (ctx, res) => setLeadSources(res.data.results)
  ),
  reduxStorePreloadItem(
    "types.moveTypes",
    (ctx) => leadsApi(ctx)?.leadMoveTypes(ctx.reduxStore?.getState()?.company?.current?.id),
    (ctx, res) => setMoveTypes(res.data.results)
  ),
  reduxStorePreloadItem(
    "types.taskTypes",
    (ctx) => leadsApi(ctx)?.taskTypes(ctx.reduxStore?.getState()?.company?.current?.id),
    (ctx, res) => setTaskTypes(res.data.results)
  ),
  reduxStorePreloadItem(
    "types.leadStages",
    (ctx) => leadsApi(ctx)?.leadsStages(ctx.reduxStore?.getState().company?.current?.id),
    (ctx, res) => setLeadStages(res.data.results)
  ),
  reduxStorePreloadItem(
    "plans.plans",
    (ctx) => paymentApi(ctx).getAllPlans(),
    (ctx, res) => setPlans(res.data.results)
  ),
];

/**
 * Ensure redux store has contains certain items
 * @param ctx
 * @param items {Array<string>} array of redux store keys
 * @return {Promise<void>}
 */
export const ensureReduxStoreLoaded = async (ctx, items) => {
  const store = ctx.reduxStore;
  const state = store.getState();
  await Promise.all(
    items.map(async (loadItem) => {
      const item = reduxStorePreloadItems.find((item) => item.name === loadItem);
      if (item) {
        if (!getProp(item.name, state)) {
          const res = await item.api(ctx);
          store.dispatch(item.reducer(ctx, res));
        }
      }
    })
  );
};

/**
 * Preload redux store with core data
 * @param ctx
 * @return {Promise<void>}
 */
export const preloadStore = async (ctx) => {
  const store = ctx.reduxStore;
  let {
    company: {current, membership},
    user,
  } = store.getState();

  if (!current) {
    membership = (await companyApi(ctx).membership()).data;
    if (membership.every((item) => !item?.is_active)) {
      Router.replace("/logout");
    } else {
      //route to select office page
    }
    store.dispatch(setMembership(membership));
    let activeCompanies = membership.filter((item) => item.is_active) || [];
    let companyId =
      activeCompanies.find((item) => item.is_selected)?.company_id ||
      activeCompanies[0]?.company_id;
    current = (await companyApi(ctx).getCompanyInfo(companyId)).data;
    let currentPlan = null;
    let nextPlan = null;
    if (current.subscription_plan) {
      const res = await paymentApi(ctx).getAllPlans();
      const planIndex = res.data.results.findIndex((it) => it.id === current.subscription_plan);
      currentPlan = res.data.results[planIndex];
      if (planIndex !== res.data.results.length - 1) {
        nextPlan = res.data.results[planIndex + 1];
      }
    }
    current.id = companyId;
    store.dispatch(setCurrentCompany(current, currentPlan, nextPlan));
  }
  if (!user) {
    user = (await authApi(ctx).getUser(current.id)).data;
    store.dispatch(setUser(user));
  }
};

/**
 * Redirect to dashboard if user is authenticated
 * @param ctx
 * @return {Promise<void>}
 */
export const redirectIfAuth = async (ctx) => {
  if (ctx.req && !cookies(ctx).sessionid) {
    return;
  }
  try {
    await companyApi(ctx).membership();
    await redirectTo(ctx, "/");
  } catch (e) {
    if (!(e.response && e.response.status === 403)) {
      throw e;
    }
  }
};

/**
 * Get authenticated user or redirect to login page
 * @param ctx {FromazPageContext}
 */
export const requireAuthenticatedUser = async (ctx) => {
  if (ctx.req && !cookies(ctx).sessionid) {
    return redirectTo(ctx, "/login");
  }
  try {
    if (ctx) {
      await preloadStore(ctx);
      const store = ctx.reduxStore;
      let {user, company} = store.getState();
      return {user, company};
    }
  } catch (e) {
    if (e.response && e.response.status === 403) {
      return redirectTo(ctx, "/logout");
    } else {
      throw e;
    }
  }
};

export const requireTrialOrPlanActivated = async (ctx) => {
  const res = await requireAuthenticatedUser(ctx);
  if (!res) return;

  const {user, company} = res;

  if (!user) return;
  let trialExpiryDate = null;

  if (company.current.trial_start) {
    trialExpiryDate = new Date(company.current.trial_start);
    trialExpiryDate.setDate(trialExpiryDate.getDate() + 30);
  }

  if (company.current.trial_start == null && !company.current.subscription_active) {
    return redirectTo(ctx, "/payment");
  }

  if (company.current.trial_start < trialExpiryDate && !company.current.subscription_active) {
    return redirectTo(ctx, "/upgrade-plan");
  }
  return {user, company};
};

async function redirectTo(ctx, path) {
  if (ctx.req) {
    ctx.res.writeHead(302, {Location: path}).end();
    ctx.res.finished = true;
  } else {
    await Router.push(path);
  }
}

/**
 * Check if user has permission to access this page, otherwise redirect to unauthorized page.
 * @param ctx
 * @param permissionCode
 * @param permissionValue [true]
 * @return user (if authenticated)
 */
export const requireUserPermission = async (ctx, permissionCode, permissionValue = true) => {
  const {user} = await requireTrialOrPlanActivated(ctx);

  const userRole = getSafeDeep(user, "role", []);

  if (checkUserPermission(userRole, permissionCode, permissionValue)) {
    return user;
  }
  await redirectTo(ctx, "/unauthorized");
};

export const requireAdminPermission = async (ctx, permissionCode) => {
  const {user} = await requireAuthenticatedUser(ctx);
  if (user?.admin_role?.[permissionCode] || user?.admin_role?.is_superuser) {
    return user;
  }
  await redirectTo(ctx, "/unauthorized");
};

export const checkAdminPermission = (adminRole, permissionCode) => {
  return !!((adminRole && adminRole?.is_superuser) || adminRole?.[permissionCode]);
};

/**
 * Check if user role has permission
 * @param userRole
 * @param permissionCode
 * @param permissionValue [true]
 * @return {boolean}
 */
export const checkUserPermission = (userRole, permissionCode, permissionValue = true) => {
  if (!userRole || !Array.isArray(userRole)) {
    return false;
  }
  const numericValue = parseInt(permissionValue);
  return (
    (isNaN(numericValue) && userRole.some((role) => role[permissionCode] === permissionValue)) ||
    userRole.some((role) => role[permissionCode] >= numericValue)
  );
};

/**
 * Show element only if user has permission
 * @param permission
 * @param permissionValue [true]
 * @param children
 */
export const PermissionGuard = ({
  anyPermission,
  permission,
  permissionValue = true,
  label,
  children,
  hidden = true,
}) => {
  const role = useSelector((state) => getProp("user.role", state, {defaultValue: []}));
  if (checkUserPermission(role, permission, permissionValue)) {
    return children;
  } else if (
    anyPermission &&
    anyPermission.some((p) => checkUserPermission(role, p, permissionValue))
  ) {
    return children;
  } else {
    return children?.props && !hidden ? (
      <div
        className={cn(children.props.className, styles.permissionGuard)}
        style={{
          ...children.props.style,
        }}
      >
        <div className={styles.header}>
          <Icon icon="plus" />
          <h3>{label}</h3>
        </div>
        <p>You don't have permission to access this component.</p>
        <p>Contact your administrator</p>
      </div>
    ) : null;
  }
};

export const AdminPermissionGuard = ({children, callback, permission, anyPermission}) => {
  const adminRole = useSelector((state) => state.user.admin_role);

  if (permission && checkAdminPermission(adminRole, permission)) {
    return children;
  } else if (anyPermission && anyPermission.some((p) => checkAdminPermission(adminRole, p))) {
    return children;
  } else {
    return callback;
  }
};
export const hasPermission = (permission) => {
  const authorized = checkAdminPermission(adminRole, permission);
  return authorized;
};

export const getRequiredPermissionLevel = (user, owner) => {
  if (getSafeDeep(user, "id") === getSafeDeep(owner, "id")) {
    return 0;
  }

  if (getSafeDeep(user, "membership.office") === getSafeDeep(owner, "membership.office")) {
    return 1;
  }

  return 2;
};

export const switchCompany = (selectedCompanyId) => {
  return async (dispatch) => {
    let {data: current} = await companyApi().getCompanyInfo(selectedCompanyId);
    let currentPlan = null;
    let nextPlan = null;
    if (current.subscription_plan) {
      const {
        data: {results: plans},
      } = await paymentApi().getAllPlans();
      const planIndex = plans.findIndex((it) => it.id === current.subscription_plan);
      currentPlan = plans[planIndex];
      if (planIndex !== plans.length - 1) {
        nextPlan = plans[planIndex + 1];
      }
    }
    current.id = selectedCompanyId.toString();
    dispatch(setCurrentCompany(current, currentPlan, nextPlan));

    const [
      {data: user},
      {
        data: {results: leadSources},
      },
      {
        data: {results: moveTypes},
      },
      {
        data: {results: taskTypes},
      },
      {
        data: {results: leadStages},
      },
    ] = await Promise.all([
      authApi().getUser(selectedCompanyId),
      leadsApi()?.leadSources(selectedCompanyId),
      leadsApi()?.leadMoveTypes(selectedCompanyId),
      leadsApi()?.taskTypes(selectedCompanyId),
      leadsApi()?.leadsStages(selectedCompanyId),
    ]);
    dispatch(setUser(user));
    dispatch(setLeadSources(leadSources));
    dispatch(setMoveTypes(moveTypes));
    dispatch(setTaskTypes(taskTypes));
    dispatch(setLeadStages(leadStages));
  };
};
