import React, {useState, useRef} from "react";
import lodash from "lodash";
import userApi from "@/api/user/user";
import uploadApi from "@/api/upload/upload";
import {handleErrors} from "@/util/helperFunctions";
import {Icon, notify, DANGER, SUCCESS} from "UI";
import styles from "./ImageEditor.module.css";
import {useDispatch} from "react-redux";
import {setUser} from "@/redux/actions/user";

const ImageEditor = ({photo, setPhoto, user, company}) => {
  const dispatch = useDispatch();
  const imageInputField = useRef(null);
  const [temporaryImage, setTemporaryImage] = useState(photo ? URL.createObjectURL(photo) : null);

  const handleClick = () => {
    imageInputField.current.click();
  };

  const handleChange = async (event) => {
    try {
      const photo = event.target.files[0];
      const res = await userApi().changePhoto(company.current.id, user.id);
      let url = res.data.s3.url;
      let fields = res.data.s3.fields;
      await uploadApi().uploadFile(photo, url, fields);
      setPhoto(photo);
      setTemporaryImage(URL.createObjectURL(photo));
      dispatch(setUser(res.data.user));
      notify(SUCCESS, "Photo will be changed.");
    } catch (error) {
      handleErrors(error, "Problem with changing photo", DANGER);
      console.log("Problem with sending attachment", error);
    }
  };

  const placeholder = user && !lodash.isEmpty(user) && user.avatar && user.avatar.image_url;

  return (
    <div className={styles["image-editor"]}>
      <input
        type="file"
        className={styles["hidden"]}
        ref={imageInputField}
        onChange={(e) => handleChange(e)}
        accept="image/*"
      />
      <div
        className={styles["image-placeholder"]}
        style={{
          backgroundImage: `url(${temporaryImage ? temporaryImage : placeholder})`,
        }}
      />
      <div className={styles["image-text-btn"]} onClick={handleClick}>
        <span className={styles["image-text"]}>Change photo</span>
        <Icon icon="pencil" color="primary" className={styles["image-icon"]} />
      </div>
    </div>
  );
};

export default ImageEditor;
