import React, {useState} from "react";

import styles from "./FormDateRangeInput.module.css";

import moment from "moment";

import {IconButton, TextInput, DatePicker} from "UI";

const FormDateRangeInput = (props) => {
  const {
    error,
    label,
    placeholder,
    name,
    form,
    defaultValue,
    validation,
    disabled,
    className,
    disableGoToToday = false,
    dateFormat = "D MM YYYY",
    Icon = (
      <IconButton
        icon="close-nav"
        style={{
          position: "absolute",
          right: "20px",
          top: "15px",
          transform: "rotate(-90deg)",
        }}
      />
    ),
    divider = "to",
  } = props;

  const [value, setValue] = useState(defaultValue || "");
  const [valueTo, setValueTo] = useState(defaultValue || "");
  const [showPicker, setShowPicker] = useState(false);

  const handleValueChange = (val) => {
    setValue(val);
  };

  const handleValueToChange = (val) => {
    setValueTo(val);
    setShowPicker(false);
  };

  return (
    <>
      <div
        className={styles.backdrop}
        style={!showPicker ? {display: "none"} : {}}
        onClick={() => setShowPicker(false)}
      ></div>
      <div>
        <label className={styles.label}>
          {label}
          {validation && validation.required && <span className="text-danger">*</span>}
        </label>
        <div className={styles["input-box"]} onClick={() => setShowPicker((prev) => !prev)}>
          <input
            className="d-none"
            name={name}
            value={[value, valueTo]}
            onChange={() => {}}
            ref={form}
            type="text"
          />
          <TextInput
            disabled={disabled}
            placeholder={value || valueTo ? "" : placeholder}
            className={className}
          />
          <div className={styles["selected-value"]}>
            {value && moment(value).format(dateFormat)} {value && divider}{" "}
            {valueTo && moment(valueTo).format(dateFormat)}
          </div>
          {Icon}
          <div className="mt-2 position-absolute" onClick={(e) => e.stopPropagation()}>
            <div className={styles["pickers-container"]}>
              <DatePicker
                setValue={handleValueChange}
                value={value}
                showPicker={showPicker}
                isRange={true}
                disableGoToToday={disableGoToToday}
              />
              <DatePicker
                setValue={handleValueToChange}
                value={valueTo}
                showPicker={showPicker}
                isRange={true}
                disableGoToToday={disableGoToToday}
              />
            </div>
          </div>
          {error && (
            <small style={{color: "red", marginTop: "3px"}}>
              {typeof error == "object" ? JSON.stringify(error) : error}
            </small>
          )}
        </div>
      </div>
    </>
  );
};

export default FormDateRangeInput;
