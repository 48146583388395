import React from "react";

import styles from "./ConfirmButton.module.css";
import cn from "classnames";

const ConfirmButton = ({disabled = false, children, className, onClick, width}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={
        !disabled
          ? cn(styles["button-container"], className)
          : cn(styles["disabled-button-container"], className)
      }
      style={{width: width}}
    >
      <span className={styles["button-text"]}>{children}</span>
    </button>
  );
};

export default ConfirmButton;
