import React from "react";
import cn from "classnames";
import {Icon} from "UI";
import styles from "./Search.module.css";

const Search = ({
  setValue,
  value,
  focused,
  isFocused = false,
  placeholder,
  onClick,
  inputClassName,
}) => {
  return (
    <div className={styles["input-container"]} onClick={onClick}>
      <Icon icon="search" className={styles["input-icon"]} color={"secondary"} />
      <input
        type="search"
        value={value}
        placeholder={placeholder || "Search"}
        className={cn(styles["input-field"], inputClassName)}
        style={{width: focused && "700px"}}
        onChange={(event) => setValue(event.target.value)}
        autoFocus={isFocused}
      />
    </div>
  );
};

export default Search;
