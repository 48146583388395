import React, {forwardRef} from "react";
import cn from "classnames";

import styles from "./Scroller.module.css";

const Scroller = ({className, children}, ref) => {
  return (
    <div ref={ref} className={cn(styles["scroller-wrapper"], className)}>
      {children}
    </div>
  );
};

export default forwardRef(Scroller);
