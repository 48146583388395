import React from "react";
import {getTrackBackground, Range} from "react-range";
import styles from "./AudioVideoProgressBar.module.css";

const AudioVideoProgressBar = ({seek, setSeek, setUserSeek}) => {
  const STEP = 0.01;
  const MIN = 0;
  const MAX = 1;

  const onChange = (val) => {
    setUserSeek(true);
    setSeek(val[0]);
  };

  return (
    <Range
      values={[seek]}
      step={STEP}
      min={MIN}
      max={MAX}
      onChange={(values) => onChange(values)}
      renderTrack={({props, children}) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            marginTop: "20px",
            height: "36px",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: "5px",
              width: "100%",
              borderRadius: "4px",
              background: getTrackBackground({
                values: [seek],
                colors: ["#596bff", "#ccc"],
                min: MIN,
                max: MAX,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({props, isDragged}) => (
        <div
          className={styles.thumb}
          {...props}
          style={{
            ...props.style,
            height: "18px",
            width: "18px",
            borderRadius: "50%",
            backgroundColor: "#596bff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 2px 6px #AAA",
            ":focus": {
              outline: "none",
            },
          }}
        />
      )}
    />
  );
};

export default AudioVideoProgressBar;
