import React, {useEffect, useState} from "react";
import moment from "moment";

import {IconButton, TextInput, TimePicker} from "UI";

import styles from "./FormTimeInput.module.css";

import cx from "classnames";

const FormTimeInput = (props) => {
  const {error, label, placeholder, name, defaultValue, form, validation, popupClass} = props;

  const [value, setValue] = useState(
    defaultValue ? moment(defaultValue).format("HH:mm") : moment().format("HH:mm")
  );
  const [showPicker, setShowPicker] = useState(false);

  //TODO: Handle value change, ask backend what type of input is expected
  const handleValueChange = (val) => {
    setValue(val);
  };
  useEffect(() => {
    if (defaultValue.length === 5) {
      setValue(defaultValue);
      return;
    }
    if (moment(defaultValue).format("HH:mm") == "Invalid date") {
      setValue(moment().format("HH:mm"));
    } else {
      setValue(moment(defaultValue).format("HH:mm"));
    }
  }, [defaultValue]);

  return (
    <>
      <div
        className={styles.backdrop}
        style={!showPicker ? {display: "none"} : {}}
        onClick={() => setShowPicker(false)}
      ></div>
      <div>
        <label className={styles.label}>
          {label}
          {validation && validation.required && <span className="text-danger">*</span>}
        </label>
        <div className={styles["input-box"]} onClick={() => setShowPicker((prev) => !prev)}>
          <input
            className="d-none"
            name={name}
            value={value}
            onChange={() => {}}
            ref={form}
            type="text"
          />
          <TextInput disabled placeholder={value ? "" : placeholder} className={styles.input} />
          <div className={styles["selected-value"]}>{value}</div>
          <IconButton
            icon="close-nav"
            style={{
              position: "absolute",
              right: "20px",
              top: "15px",
              transform: "rotate(-90deg)",
            }}
          />
          <div
            className={cx("mt-2 position-absolute", {[popupClass]: popupClass})}
            onClick={(e) => e.stopPropagation()}
          >
            <TimePicker setValue={handleValueChange} value={value} showPicker={showPicker} />
          </div>
          {error && (
            <small style={{color: "red", marginTop: "3px"}}>
              {typeof error == "object" ? JSON.stringify(error) : error}
            </small>
          )}
        </div>
      </div>
    </>
  );
};

export default FormTimeInput;
