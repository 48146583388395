import React, {useEffect, useState} from "react";

import {SelectInput} from "UI";

import styles from "./FormSelectInput.module.css";

const FormSelectInput = ({
  label,
  placeholder,
  options,
  name,
  form,
  defaultValue,
  loadOptions,
  format,
  renderLabel,
  error,
  validation,
  country,
  moving,
  stage,
  onFocus,
  source,
  className,
  optionsBoxClassName,
  optionClassName,
  task,
  timeZone,
  simpleDefaultValue,
  value,
  setValue,
  readOnly = true,
  onChange,
}) => {
  const [val, _setVal] = useState(value);

  const setNewValue = (newValue) => {
    _setVal(newValue);
    if (onChange) {
      onChange(newValue);
    }
    if (setValue) {
      setValue(name, newValue, {shouldValidate: true, shouldDirty: true});
    }
  };

  useEffect(() => {
    value && _setVal(value);
  }, [value]);

  return (
    <div className={styles["input-box"]}>
      {label && (
        <label className={styles.label}>
          {label}
          {validation && validation.required && <span className="text-danger">*</span>}
        </label>
      )}
      <input
        className="d-none"
        name={name}
        onChange={() => {}}
        value={val ? JSON.stringify(val) : ""}
        ref={form(validation)}
        type="text"
        autoComplete={"disabled"}
      />
      <SelectInput
        value={val}
        setValue={setNewValue}
        placeholder={placeholder}
        options={options}
        loadOptions={loadOptions}
        format={format}
        renderLabel={renderLabel}
        defaultValue={defaultValue}
        country={country}
        onFocus={onFocus}
        timeZone={timeZone}
        moving={moving}
        stage={stage}
        source={source}
        className={className}
        optionsBoxClassName={optionsBoxClassName}
        optionClassName={optionClassName}
        error={error}
        task={task}
        simpleDefaultValue={simpleDefaultValue}
        readOnly={readOnly}
      />
      {error && (
        <small style={{color: "#f40034", marginTop: "3px"}}>
          {typeof error == "object" ? error.message : error}
        </small>
      )}
    </div>
  );
};

export default FormSelectInput;
