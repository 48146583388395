import React, {forwardRef} from "react";

import SVG from "react-inlinesvg";
import cn from "classnames";

import classes from "./Icon.module.css";

const Icon = (
  {
    icon,
    color,
    bgColor,
    className,
    onClick,
    isLarge = false,
    title,
    width,
    height,
    isHoverPointer = true,
    ...rest
  },
  ref
) => {
  const svg = require(`./icons/${icon}.svg`);

  let colorProps = {fill: color, stroke: color};
  colorProps = Object.fromEntries(Object.entries(colorProps).filter(([_, val]) => !!val));
  return (
    <div
      title={title}
      className={cn(className, classes[color])}
      onClick={onClick}
      style={{backgroundColor: bgColor}}
      ref={ref}
      {...rest}
    >
      <SVG
        src={svg}
        {...colorProps}
        {...(width && height ? {width, height} : {})}
        className={`${isLarge ? classes["svg-large"] : ""} ${
          isHoverPointer ? classes["svg-pointer"] : ""
        }`}
      />
    </div>
  );
};

export default forwardRef(Icon);
