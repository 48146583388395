import {
  SET_LEAD_SOURCES,
  SET_MOVE_TYPES,
  SET_LEAD_STAGES,
  SET_TASK_TYPES,
  TYPES_RESET_STATE,
  SET_STAGE_TYPES,
  SET_TEMPLATES,
  ADD_TEMPLATE,
  DELETE_TEMPLATES,
  UPDATE_TEMPLATE,
  ADD_INTERVAL,
  DELETE_INTERVAL,
  UPDATE_INTERVAL,
} from "../constants";

export const setLeadSources = (leadSources) => ({
  type: SET_LEAD_SOURCES,
  leadSources,
});

export const setMoveTypes = (moveTypes) => ({
  type: SET_MOVE_TYPES,
  moveTypes,
});

export const setLeadStages = (leadStages) => ({
  type: SET_LEAD_STAGES,
  leadStages,
});

export const setTaskTypes = (taskTypes) => ({
  type: SET_TASK_TYPES,
  taskTypes,
});

export const resetTypesState = () => ({
  type: TYPES_RESET_STATE,
});

export const setStageTypes = (stageTypes) => ({
  type: SET_STAGE_TYPES,
  stageTypes,
});

export const setTemplates = (templates) => ({
  type: SET_TEMPLATES,
  templates,
});

export const addTemplate = (template) => ({
  type: ADD_TEMPLATE,
  template,
});

export const deleteTemplate = (id) => ({
  type: DELETE_TEMPLATES,
  id,
});

export const updateTemplate = (template) => ({
  type: UPDATE_TEMPLATE,
  template,
});

export const addInterval = (interval) => ({
  type: ADD_INTERVAL,
  interval,
});

export const deleteInterval = (id) => ({
  type: DELETE_INTERVAL,
  id,
});

export const updateInterval = (interval) => ({
  type: UPDATE_INTERVAL,
  interval,
});
