import React from "react";
import cn from "classnames";
import styles from "./InfoTable.module.css";

const InfoTable = (props) => {
  const {data, stageId} = props;
  return (
    <div className={cn(styles.table, props.className)}>
      {data.map((item) => {
        return (
          <div key={item.name + stageId} className={styles.row}>
            <div style={{marginBottom: "5px"}}>
              {item.renderKey ? item.renderKey() : item.label}
            </div>
            <div>{item.renderValue ? item.renderValue() : item.value}</div>
          </div>
        );
      })}
    </div>
  );
};

export default InfoTable;
