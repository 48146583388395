import {getApiClient} from "@/api/util";

export default (ctx) => ({
  getAvailableNumbers: (company_id, area_code, country_code, contains) =>
    getApiClient(ctx).get(`/company/${company_id}/calls/number/`, {
      params: {
        area_code: area_code,
        country_code: country_code,
        locality: contains,
      },
    }),

  buyNumber: (company_id, phone_number, office) =>
    getApiClient(ctx).post(`/company/${company_id}/calls/number/`, {
      phone_number: phone_number,
      office: office,
    }),

  getPurchasedNumber: (company_id) => getApiClient(ctx).get(`/company/${company_id}/`),
});
