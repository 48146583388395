import React from "react";

import {NumberInput} from "UI";

import styles from "./FormNumberInput.module.css";

const FormNumberInput = (props) => {
  const {
    error,
    label,
    placeholder,
    name,
    defaultValue,
    onFocus,
    form,
    validation,
    type,
    value,
    className,
    onChange,
    flag,
    tooltip,
    hideArrows,
    min,
    max,
    suffix,
    step,
    allowDecimal,
  } = props;

  return (
    <div className={styles["input-box"]} id={`tt-${name}`}>
      <div className={styles.tooltipContainer}>
        {flag ? (
          <div className={styles["flag-box"]}>
            <label className={styles.label}>
              {label}
              {validation && validation.required && <span className="text-danger">*</span>}
            </label>
            <label className={styles["flag-text"]}>{flag}</label>
          </div>
        ) : (
          <label className={styles.label}>
            {label}
            {validation && validation.required && <span className="text-danger">*</span>}
          </label>
        )}
        {tooltip ? <div className={styles.tooltip}>{tooltip}</div> : ""}
      </div>

      <NumberInput
        error={error}
        defaultValue={defaultValue}
        form={form ? form(validation) : null}
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
        onFocus={onFocus}
        disabled={validation?.disabled}
        className={className}
        onChange={onChange}
        hideArrows={hideArrows}
        min={min}
        max={max}
        step={step}
        suffix={suffix}
        allowDecimal={allowDecimal}
      />
      {error && (
        <small style={{color: "#f40034", marginTop: "3px"}}>
          {typeof error == "object" ? error.message : error}
        </small>
      )}
    </div>
  );
};

export default FormNumberInput;
