import {getApiClient} from "@/api/util";
import qs from "qs";

export default (ctx) => ({
  list: (company_id, offset = 0, limit = 50, search = "", filters) =>
    getApiClient(ctx).get(`/company/${company_id}/leads/`, {
      params: {limit, offset, search, ...filters},
      paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: "repeat"});
      },
    }),
  count: (company_id) => getApiClient(ctx).get(`/company/${company_id}/leads/count/`),
  create: (company_id, lead) => {
    return getApiClient(ctx).post(`/company/${company_id}/leads/`, lead);
  },
  createFromHash: (lead) => {
    return getApiClient(ctx).post(`/lead/intake/`, lead);
  },
  getHash: (company_id, lead_id) => {
    return getApiClient(ctx).get(`/company/${company_id}/leads/${lead_id}/hash/`);
  },
  duplicate: (company_id, data) => {
    return getApiClient(ctx).post(`/company/${company_id}/leads/duplicate/`, data);
  },
  get: (company_id, lead_id) => getApiClient(ctx).get(`/company/${company_id}/leads/${lead_id}/`),
  getFromHash: (lead_hash) => getApiClient(ctx).get(`/lead/${lead_hash}/`),
  getBooked: (company_id, from, to, office, user) =>
    getApiClient(ctx).get(
      `/company/${company_id}/leads/booked/?from=${from}&to=${to}${
        office ? "&office=" + office : ""
      }${user ? "&user=" + user : ""}`
    ),
  update: (company_id, lead_id, data) =>
    getApiClient(ctx).put(`/company/${company_id}/leads/${lead_id}/`, data),
  patch: (company_id, lead_id, data) =>
    getApiClient(ctx).patch(`/company/${company_id}/leads/${lead_id}/`, data),
  delete: (company_id, lead_id) =>
    getApiClient(ctx).delete(`/company/${company_id}/leads/${lead_id}/`),
  leadSources: (company_id) => getApiClient(ctx).get(`/company/${company_id}/lead-sources/`),
  leadMoveTypes: (company_id) => getApiClient(ctx).get(`/company/${company_id}/lead-movetypes/`),
  leadsStages: (company_id) => getApiClient(ctx).get(`/company/${company_id}/stage/`),
  taskTypes: (company_id) => getApiClient(ctx).get(`/company/${company_id}/task_type/`),
  addLeadNumber: (company_id, lead_id, phone) =>
    getApiClient(ctx).post(`/company/${company_id}/leads/${lead_id}/phone/`, phone),

  deleteDocument: (company_id, lead_id, pk) =>
    getApiClient().delete(`/company/${company_id}/leads/${lead_id}/${pk}/`),
  uploadDocument: (company_id, lead_id, upload_type, formData, progress) =>
    getApiClient(ctx).post(
      `/company/${company_id}/leads/${lead_id}/upload/${upload_type}/`,
      formData,
      progress
    ),
  getDocument: (company_id, lead_id, upload_type) =>
    getApiClient(ctx).get(`/company/${company_id}/leads/${lead_id}/upload/${upload_type}/`),
  editLeadNumber: (company_id, lead_id, phone_id, phone) =>
    getApiClient(ctx).put(`/company/${company_id}/leads/${lead_id}/phone/${phone_id}/`, phone),
  removeLeadNumber: (company_id, lead_id, phone_id) =>
    getApiClient(ctx).delete(`/company/${company_id}/leads/${lead_id}/phone/${phone_id}/`),
  addLeadEmail: (company_id, lead_id, email) =>
    getApiClient(ctx).post(`/company/${company_id}/leads/${lead_id}/email/`, email),
  editLeadEmail: (company_id, lead_id, email_id, email) =>
    getApiClient(ctx).put(`/company/${company_id}/leads/${lead_id}/email/${email_id}/`, email),
  removeLeadEmail: (company_id, lead_id, email_id) =>
    getApiClient(ctx).delete(`/company/${company_id}/leads/${lead_id}/email/${email_id}/`),
  addLeadNote: (company_id, note) => getApiClient(ctx).post(`/company/${company_id}/notes/`, note),
  editLeadNote: (company_id, note_id, note) =>
    getApiClient(ctx).put(`/company/${company_id}/notes/${note_id}/`, note),
  getLeadNote: (company_id, note_id) =>
    getApiClient(ctx).get(`/company/${company_id}/notes/${note_id}/`),
  getNewLeads: (company_id, offset, limit) =>
    getApiClient(ctx).get(`/company/${company_id}/leads/new/`, {
      params: {
        offset,
        limit,
      },
    }),
  getNewLeadsCount: (company_id) =>
    getApiClient(ctx).get(`/company/${company_id}/leads/new/count/`, {}),
  getNewMessages: (company_id) =>
    getApiClient(ctx).get(`/company/${company_id}/users/me/new-messages/`),
  getTimeline: (companyId, moveId) =>
    getApiClient(ctx).get(`/company/${companyId}/leads/${moveId}/timeline/`),
  generatePDFTimeline: (companyId, moveId, name) =>
    getApiClient(ctx).get(`/company/${companyId}/leads/${moveId}/timeline/pdf/`, {
      params: {
        name,
      },
    }),
  rateLead: (company_id, lead_id, comment, star, status) =>
    getApiClient(ctx).post(`/company/${company_id}/leads/${lead_id}/rate/`, {
      comment,
      star,
      status,
    }),
  deleteLeadsAddress: (company_id, leadId, addressId) =>
    getApiClient(ctx).delete(
      `/company/${company_id}/address-delete/${addressId}/?lead_id=${leadId}`
    ),
  importLeads: (company_id, formData) =>
    getApiClient(ctx).post(`/company/${company_id}/leads/import/`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  getFinalResults: (company_id, move_id) =>
    getApiClient(ctx).get(`/company/${company_id}/lead/${move_id}/send-results/`),
  sendResults: (company_id, move_id, data) =>
    getApiClient(ctx).post(`/company/${company_id}/lead/${move_id}/send-results/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getPresignedPost: (company_id, data) =>
    getApiClient(ctx).post(`/company/${company_id}/presign-post/`, data),
});
