import React from "react";

import styles from "./ToggleOptionsButton.module.css";
import cn from "classnames";

import {Icon} from "UI";

const ToggleOptionsButton = (props) => {
  const {
    active, //boolean
    onClick, //toggle value
    text1,
    text2,
    icon1 = "check-mark",
    icon2 = "check-mark",
    className,
    innerClassName,
  } = props;

  return (
    <div className={cn(styles["button-container"], className)} onClick={onClick}>
      <span className={styles["button-text"]}>{active ? text1 : text2}</span>
      <div className={cn(styles["icon-container"], innerClassName)}>
        <Icon icon={active ? icon1 : icon2} />
      </div>
    </div>
  );
};

export default ToggleOptionsButton;
