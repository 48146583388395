import React, {useState} from "react";
import moment from "moment";
import DayPicker, {DateUtils} from "react-day-picker";

import Head from "next/head";

import {IconButton} from "UI";

import styles from "./DateWeekPicker.module.css";
import {DateRangePickerStyles} from "./DateWeekPicker.styles";

const Navbar = (props) => {
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={props.removeBox ? {padding: "0 16px 0 16px"} : {padding: "16px"}}
    >
      <IconButton
        onClick={() => props.onPreviousClick()}
        icon="close-nav"
        style={{
          lineHeight: 0,
        }}
      />
      <p className={styles["date"]}>{moment(props.month).format("MMM YYYY")}</p>
      <IconButton
        onClick={() => props.onNextClick()}
        icon="close-nav"
        style={{
          lineHeight: 0,
          transform: "rotate(180deg)",
        }}
      />
    </div>
  );
};

const Caption = (_) => {
  return null;
};

const DateRangePicker = ({ref, removeBox, dateRange, setDateRange, daysToPick = 1}) => {
  const [isWeek, setIsWeek] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);

  const handleDayClick = (day, {selected}) => {
    const range = DateUtils.addDayToRange(day, dateRange);
    if (isWeek) {
      setSelectedDay(null);
      setDateRange({
        from: day,
        to: moment(day).add({days: daysToPick}).toDate(),
      });
    } else {
      selected ? undefined : setSelectedDay(day);
      setDateRange({from: day, to: day});
    }
  };

  const modifiers = {start: dateRange.from, end: dateRange.to};

  return (
    <>
      <Head>
        <style>{DateRangePickerStyles}</style>
        {removeBox && (
          <style>{`
            .DayPicker{
              outline: none;
              background: transparent;
              height: auto;
              border-radius: 0;
              box-shadow: none;
            }
          `}</style>
        )}
      </Head>
      <div ref={ref} className={styles["input-box"]}>
        {removeBox && <p className={styles.title}>Pick Date</p>}
        <>
          <DayPicker
            firstDayOfWeek={1}
            showOutsideDays
            todayButton={
              <div className={styles.buttonGroup}>
                <span
                  className={styles.today}
                  onClick={() => {
                    setIsWeek(!isWeek);
                    !isWeek
                      ? setDateRange({
                          from: moment().toDate(),
                          to: moment().add({days: daysToPick}).toDate(),
                        })
                      : setDateRange({
                          from: moment().toDate(),
                          to: moment().toDate(),
                        });
                  }}
                >
                  {isWeek ? "Week" : "Day"}
                </span>
                <span
                  className={styles.today}
                  onClick={() =>
                    setDateRange({
                      from: undefined,
                      to: undefined,
                    })
                  }
                >
                  Clear
                </span>
              </div>
            }
            captionElement={<Caption />}
            navbarElement={<Navbar removeBox />}
            onDayClick={handleDayClick}
            selectedDays={selectedDay ? selectedDay : [dateRange.from, dateRange]}
            modifiers={modifiers}
          />
        </>
      </div>
    </>
  );
};

export default DateRangePicker;
