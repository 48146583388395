import React from "react";

import cn from "classnames";
import styles from "./DayWeekMonthButton.module.css";
import moment from "moment";

const DayWeekMonthButton = (props) => {
  const {timespan, currentTimespan, setCurrentTimespan, currentDate} = props;

  let isToday = moment(new Date().setHours(0, 0, 0, 0)).isSame(moment(currentDate));

  return (
    <div className={styles["buttons-container"]}>
      {timespan.map((button, index) => {
        return (
          <div
            key={index}
            className={cn(styles["button-container"], {
              [styles["active-button"]]: currentTimespan.type == button,
            })}
            onClick={() => {
              let tempTimespan = {...currentTimespan, type: timespan[index]};
              setCurrentTimespan(tempTimespan);
            }}
          >
            <span
              className={cn(styles["button-text"], {
                [styles["active-button-text"]]: currentTimespan.type == button,
              })}
            >
              {button == "Day" && isToday ? "Today" : button}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default DayWeekMonthButton;
