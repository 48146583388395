import React from "react";
import cn from "classnames";
import styles from "./Render.module.css";
import {getSafeDeep} from "@/util/helperFunctions";

export function UserRender({option, setValue, value, className}) {
  return (
    <div className={styles.box} onClick={() => setValue(option)}>
      <div className={styles.content}>
        <div
          className={styles.icon}
          style={{
            backgroundImage: `url(${
              option.avatar && option.avatar
                ? getSafeDeep(option, "avatar.image_url")
                : getSafeDeep(option, "photo.image_url")
            })`,
          }}
        >
          {/* icon */}
        </div>
        <div className={styles["details-container"]}>
          <p className={styles.primary}>
            {JSON.stringify(option) == value ? (
              <b>{`${option.first_name} ${option.last_name}`}</b>
            ) : (
              `${option.first_name} ${option.last_name}`
            )}
          </p>
          <p className={styles.secondary}>
            {Array.isArray(option.email) ? option.email[0].email : option.email}
          </p>
        </div>
      </div>
    </div>
  );
}

export const TextRender = React.forwardRef(function TextRender(
  {option, setValue, value, className},
  ref
) {
  return (
    <div
      ref={ref}
      className={cn(styles.box, {
        [styles.textBoxActive]: JSON.stringify(option) == JSON.stringify(value),
      })}
      onClick={() => setValue(option)}
    >
      <div className={styles.content}>
        <p
          className={cn(styles.primary, className, {
            [styles.textLabelActive]: JSON.stringify(option) == JSON.stringify(value),
          })}
        >
          {option.label}
        </p>
      </div>
    </div>
  );
});

export const LeadRender = React.forwardRef(function LeadRender({option, setValue, value}, ref) {
  const userIcon = require(`UI/Icon/icons/user.svg`);
  return (
    <div ref={ref} className={styles.box} onClick={() => setValue(option)}>
      <div className={styles.content}>
        <div
          className={styles.icon}
          style={{
            backgroundImage: "url(" + getSafeDeep(option, "photo.image_url", userIcon) + ")",
          }}
        >
          {/* icon */}
        </div>
        <div className={styles["details-container"]}>
          <p className={styles.primary}>
            {JSON.stringify(option) == value ? (
              <b>{`${option.first_name} ${option.last_name}`}</b>
            ) : (
              `${option.first_name} ${option.last_name}`
            )}
          </p>
          <p className={styles.secondary}>{option?.email[0]?.email || ""}</p>
        </div>
      </div>
    </div>
  );
});
const formats = {
  lead: LeadRender,
  user: UserRender,
  text: TextRender,
};
export function getRenderComponent(format) {
  return formats[format];
}
