import React from "react";
import cn from "classnames";

import styles from "./AddAnotherButton.module.css";
import {Icon} from "UI";

const AddAnotherButton = ({children, className, icon, ...props}) => {
  const {iconBackground, ...btnProps} = props;
  return (
    <button {...btnProps} className={cn(styles.add, className)}>
      {children}{" "}
      {iconBackground ? (
        <div className={styles["icon-background"]}>
          <Icon icon={icon || "plus"} color={"white"} className={styles["icon"]} />
        </div>
      ) : (
        <span className={styles["secondary-icon"]}>&#43;</span>
      )}
    </button>
  );
};

export default AddAnotherButton;
