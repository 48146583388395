import React, {useState} from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import defaultUser from "@/assets/default-user.svg";
import styles from "./Avatar.module.css";

const Avatar = ({children, image, width, height, radius, className}) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div
      className={cn(styles.container, className)}
      style={{
        width: width ? width : height ? "auto" : "100%",
        height: height ? height : width ? "auto" : "100%",
        borderRadius: radius,
        backgroundImage: `url(${loaded ? image : defaultUser})`,
      }}
    >
      <img src={image} alt="avatar" onLoad={() => setLoaded(true)} style={{display: "none"}} />
      {children}
    </div>
  );
};

Avatar.propTypes = {
  children: PropTypes.node,
  radius: PropTypes.string,
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  width: "40px",
  height: "40px",
  radius: "50%",
};

export default React.memo(Avatar);
