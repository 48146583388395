import getConfig from "next/config";
import _ from "lodash";

const eventListeners = {};

let socket;

const onSocketMessageReceived = (event) => {
  if (event.data) {
    let res = JSON.parse(event.data);

    const listeners = eventListeners[res.action_type] || [];
    listeners.forEach((listener) => {
      listener(res);
    });
  }
};

const onSocketError = (err) => {
  console.error(err);
  connectWebsocket();
};

const connectWebsocket = () => {
  try {
    const host = getConfig().publicRuntimeConfig.websocketHost;
    socket = new WebSocket(`${host}ws/notification/`);
    socket.addEventListener("message", onSocketMessageReceived);
    socket.addEventListener("error", onSocketError);
  } catch (error) {
    console.log(error);
  }
};

export const addMessageListener = (actionType, listener) => {
  if (_.isArray(actionType)) {
    actionType.forEach((type) => addMessageListener(type, listener));
    return;
  }
  if (!eventListeners[actionType]) {
    eventListeners[actionType] = [];
  }
  eventListeners[actionType].push(listener);
};

export const removeMessageListener = (actionType, listener) => {
  if (_.isArray(actionType)) {
    actionType.forEach((type) => removeMessageListener(type, listener));
    return;
  }
  if (!eventListeners[actionType]) {
    eventListeners[actionType] = [];
  }
  const listenerIndex = eventListeners[actionType].indexOf(listener);
  if (listenerIndex !== -1) {
    eventListeners[actionType].splice(listenerIndex, 1);
  }
};

export const connect = () => {
  if (!socket) {
    connectWebsocket();
  }
};

export const disconnect = () => {
  if (!socket) return;
  socket.close();
  socket = null;
};
