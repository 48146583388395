import React, {useState} from "react";
import cn from "classnames";

import styles from "./SliderButton.module.css";

const CreateNewButton = ({
  firstOption,
  secondOption,
  onClick = () => {},
  _setSide = () => {},
  _side = undefined,
  className,
  activeClassName,
  passiveClassName,
}) => {
  firstOption = firstOption || "All Survey";
  secondOption = secondOption || "Live Survey";
  const [side, setSide] = useState(true);
  const onClickHandler = () => {
    setSide(!side);
    _setSide(!_side);
    onClick();
  };

  const SecondActive = () => (
    <div className={cn(styles.container, className)}>
      <span onClick={() => onClickHandler()} className={cn(styles.pasive, passiveClassName)}>
        {firstOption || "firstOption"}
      </span>
      <button className={cn(styles.active, activeClassName)} onClick={onClickHandler}>
        {secondOption || "secondOption"}
      </button>
    </div>
  );

  const FirstActive = () => (
    <div className={cn(styles.container, className)}>
      <button className={cn(styles.active, activeClassName)} onClick={onClickHandler}>
        {firstOption || "firstOption"}
      </button>
      <span onClick={() => onClickHandler()} className={cn(styles.pasive, passiveClassName)}>
        {secondOption || "secondOption"}
      </span>
    </div>
  );

  if (side) {
    return <FirstActive />;
  }
  return <SecondActive />;
};

export default CreateNewButton;
