import {SET_USER, USER_RESET_STATE} from "../constants";

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

export const resetUserState = () => ({
  type: USER_RESET_STATE,
});
