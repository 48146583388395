import React from "react";
import cn from "classnames";
import moment from "moment";

import {Icon} from "UI";

import styles from "./TimePicker.module.css";

const TimePicker = (props) => {
  const {
    showPicker,
    value = "00:15",
    setValue,
    removeBox,
    minTime,
    label,
    marginTopOff,
    setWidth,
    hoursMinutesLabel,
  } = props;

  const [hours, minutes] = value
    .split(":")
    .map((el, index) =>
      el || el === 0 ? parseInt(el) : moment().get(index === 0 ? "hour" : "minute")
    );

  const handleChange = (hours, minutes) => {
    setValue(`${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`);
  };

  const setHours = (newHours) => {
    handleChange(newHours, minutes);
  };

  const setMinutes = (newMinutes) => {
    handleChange(hours, newMinutes);
  };

  return (
    <div
      className={cn(styles["input-box"], {[styles.removeBox]: removeBox})}
      style={{display: !showPicker ? "none" : "block", width: setWidth ? setWidth : "150px"}}
    >
      <div className={styles.title}>{label ? label : "Pick time"}</div>
      {hoursMinutesLabel ? (
        <span style={{color: "var(--completed-gray)", fontSize: "14px"}}>
          hours &emsp;&emsp;&emsp;&emsp;&emsp;&#160;&#160; minutes
        </span>
      ) : (
        ""
      )}

      <div className="d-flex">
        <div className={styles["number-input"]} style={marginTopOff ? {marginTop: "0"} : {}}>
          <input
            type="number"
            className={styles["time-input"]}
            onChange={(e) => {
              if (e.target.value > 23) return;
              e.preventDefault();
              setHours(e.target.value);
            }}
            value={hours}
            min={minTime?.hour || 0}
            max={23}
          ></input>
          <div className={styles["icons"]}>
            <Icon
              icon={"chevron-down"}
              className={styles["icon-up"]}
              onClick={() => {
                setHours(hours + 1);
              }}
            />
            <Icon
              icon={"chevron-down"}
              className={styles["icon-down"]}
              onClick={() => {
                setHours(hours - 1);
              }}
            />
          </div>
        </div>
        <div className={styles["number-input"]} style={marginTopOff ? {marginTop: "0"} : {}}>
          <input
            className={styles["time-input"]}
            type="number"
            min={minTime?.minute || 0}
            max={59}
            onChange={(e) => {
              if (e.target.value > 59) return;
              e.preventDefault();
              setMinutes(e.target.value);
            }}
            value={minutes}
          ></input>
          <div className={styles["icons"]}>
            <Icon
              icon={"chevron-down"}
              className={styles["icon-up"]}
              onClick={() => {
                setMinutes(minutes + 1);
              }}
            />
            <Icon
              icon={"chevron-down"}
              className={styles["icon-down"]}
              onClick={() => {
                setMinutes(minutes - 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
