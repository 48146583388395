import React from "react";

import {MultiSelectDropdown} from "UI";
import {Controller} from "react-hook-form";

import styles from "./FormMultiSelectDropdown.module.css";

// import cx from 'classnames'

const FormMultiSelectDropdown = (props) => {
  const {
    error,
    label,
    placeholder,
    name,
    validation,
    defaultValue,
    className,
    disabledInput,
    flag,
    options,
    renderOption,
    disableSelectAll,
    optionsContainerClassName,
    control,
    isOpen,
    modalPartsRefs,
    setIsOpen,
    loadOptions,
    disableMultiselect,
    onChange: customOnChange,
    onAddOption,
  } = props;

  return (
    <div className={styles["input-box"]} id={`tt-${name}`}>
      <div className={styles.tooltipContainer}>
        {flag ? (
          <div className={styles["flag-box"]}>
            <label className={styles.label}>
              {label}
              {validation && validation.required && <span className="text-danger">*</span>}
            </label>
            <label className={styles["flag-text"]}>{flag}</label>
          </div>
        ) : (
          label && (
            <label className={styles.label}>
              {label}
              {validation && validation.required && <span className="text-danger">*</span>}
            </label>
          )
        )}
      </div>

      <Controller
        control={control}
        name={name}
        rules={validation}
        defaultValue={defaultValue}
        className={!error ? className : [`${className}, ${styles["danger"]}`]}
        render={({onChange, onBlur, value}) => {
          return (
            <MultiSelectDropdown
              selected={value}
              onSelect={(e) => {
                if (customOnChange) customOnChange(e);
                onChange(e);
              }}
              options={options}
              placeholder={placeholder}
              disabledInput={disabledInput}
              className={className}
              renderOption={renderOption}
              disableSelectAll={disableSelectAll}
              optionsContainerClassName={optionsContainerClassName}
              onBlur={onBlur}
              isOpen={isOpen || false}
              modalPartsRefs={modalPartsRefs}
              setIsOpen={setIsOpen}
              loadOptions={loadOptions}
              disableMultiselect={disableMultiselect}
              onAddOption={onAddOption}
            />
          );
        }}
      />

      {error && (
        <small style={{color: "#f40034", marginTop: "3px"}}>
          {typeof error == "object" ? error.message : error}
        </small>
      )}
    </div>
  );
};

export default FormMultiSelectDropdown;
