import React from "react";

import styles from "./AddNewTaskButton.module.css";

import {Icon} from "UI";

const AddNewTaskButton = ({onClick}) => (
  <button className={styles["add-new-button"]} onClick={onClick}>
    <div className={styles["add-new-button-content"]}>
      Add new event
      <div className={styles["plus-container"]}>
        <Icon icon="plus" color="white" />
      </div>
    </div>
  </button>
);

export default AddNewTaskButton;
