import React from "react";

import {TextInput} from "UI";

import styles from "./FormTextInput.module.css";

const FormTextInput = ({
  error,
  label,
  placeholder,
  name,
  defaultValue,
  onFocus,
  form,
  validation,
  type,
  value,
  className,
  onChange,
  hide,
  disabled,
  renderError = true,
}) => {
  return (
    <div className={styles["input-box"]} style={hide ? {display: "none", width: 0} : {}}>
      {label && (
        <label className={styles.label}>
          {label}
          {validation && validation.required && <span className="text-danger">*</span>}
        </label>
      )}
      <TextInput
        error={error}
        defaultValue={defaultValue}
        form={form ? form(validation) : null}
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
        onFocus={onFocus}
        disabled={validation?.disabled ? validation?.disabled : disabled}
        className={!error ? className : [`${className}, ${styles["danger"]}`]}
        onChange={onChange}
      />
      {renderError && error && (
        <small style={{color: "#f40034", marginTop: "3px"}}>
          {typeof error == "object" ? error.message : error}
        </small>
      )}
    </div>
  );
};

export default FormTextInput;
