import React from "react";
import cn from "classnames";
import styles from "./SwitchButton.module.css";

const SwitchButton = ({checked, setChecked, label, className}) => {
  return (
    <div className={cn(styles.container, className)}>
      {!!label && <div className={styles.label}>{label}</div>}
      <div className={styles.switch} onClick={() => setChecked(!checked)}>
        <input type="checkbox" checked={checked || false} readOnly />
        <span className={cn(styles.slider, styles.round)}></span>
      </div>
    </div>
  );
};

export default SwitchButton;
