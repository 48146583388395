import {
  COMPANY_RESET_STATE,
  SET_CURRENT_COMPANY,
  SET_MEMBERSHIP,
  SET_COMPANY_PLAN,
  SET_COMPANY_TRIAL,
} from "../constants";

export const setCurrentCompany = (company, plan, nextPlan) => ({
  type: SET_CURRENT_COMPANY,
  company,
  plan,
  nextPlan,
});

export const setCompanyPlan = (currentPlan, nextPlan) => ({
  type: SET_COMPANY_PLAN,
  plan: currentPlan,
  nextPlan,
});

export const setCompanyTrial = () => ({
  type: SET_COMPANY_TRIAL,
  trial: new Date(),
});

export const setMembership = (membership) => ({
  type: SET_MEMBERSHIP,
  membership,
});

export const resetCompanyState = () => ({
  type: COMPANY_RESET_STATE,
});
