import React, {useEffect} from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import styles from "./SelectField.module.css";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SelectField = ({options, multi, creatable, setSelected, placeholder, defaultValue}) => {
  const handleChange = (newValue) => {
    setSelected(newValue);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "12px",
      minHeight: "42px",
      borderWidth: "0",
      borderColor: "#ffffff",
      boxShadow: "0 0 0 0",
    }),
    container: (provided, state) => ({
      ...provided,
      marginRight: "25px",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "#596bff",
      borderRadius: "10px",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.27px",
      color: "#ffffff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.27px",
      color: "#ffffff",
    }),
    menu: (provided, state) => ({
      ...provided,
      borderRadius: "12px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#727e8c",
      padding: "10px 10px 10px 1px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      ":hover": {
        backgroundColor: "#4756cc",
        color: "white",
        borderTopRightRadius: "12px",
        borderBottomRightRadius: "12px",
        cursor: "pointer",
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      ":hover": {
        cursor: "pointer",
      },
    }),
  };

  const CustomOption = ({innerProps, data, value, label}) => {
    const {avatar, subLabel} = data;
    return (
      <div {...innerProps} className={styles.container}>
        {/* iMAGE  */}
        {avatar != null && (
          <div
            style={{
              backgroundImage: `url(${avatar})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className={styles.image}
          ></div>
        )}

        {/* name and email  */}
        <div className={styles.detailsbox}>
          {subLabel != null && <span className={styles.name}>{subLabel} </span>}
          {label != null && <span className={styles.email}> {label} </span>}
        </div>
      </div>
    );
  };

  const handleInputChange = (inputValue, {action, prevInputValue}) => {
    switch (action) {
      case "input-change":
        return inputValue;
      case "menu-close":
        let menuIsOpen = undefined;
        if (prevInputValue) {
          menuIsOpen = true;
        }
        return prevInputValue;
      default:
        return prevInputValue;
    }
  };

  const onKeyDown = (event) => {
    switch (event.key) {
      case "Enter":
        if (event.target.value.match(emailRegex)) {
        } else {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSelected(null);
  }, []);

  return creatable === true ? (
    <CreatableSelect
      isMulti={multi}
      onChange={handleChange}
      options={options}
      closeMenuOnSelect={false}
      components={{Option: CustomOption}}
      placeholder={<div className={styles.input}>{placeholder}</div>}
      onInputChange={handleInputChange}
      styles={customStyles}
      onKeyDown={onKeyDown}
      defaultValue={defaultValue || ""}
    />
  ) : (
    <Select
      options={options}
      isMulti={multi}
      onChange={handleChange}
      closeMenuOnSelect={true}
      placeholder={<div className={styles.input}>{placeholder}</div>}
      styles={customStyles}
    />
  );
};

export default SelectField;
