import React, {useState} from "react";
import styles from "./ImportLeads.module.css";
import {Spinner} from "reactstrap";
import {defaultFieldsMapping} from "./helper";

import leadsApi from "@/api/leads/leads";
import {useSelector} from "react-redux";
import {notify, DANGER, SUCCESS} from "UI";
import {FileUploader} from "react-drag-drop-files";

const ImportLeads = ({handleCloseModal, handleRefresh}) => {
  const [isLoading, setIsLoading] = useState(false);
  const company = useSelector((state) => state.company);
  const [file, setFile] = useState(null);

  const handleUploadFile = async () => {
    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", ".csv");
      formData.append("fields_mapping", JSON.stringify(defaultFieldsMapping));

      await leadsApi().importLeads(company.current.id, formData);
      await handleRefresh();
      handleCloseModal();
      notify(SUCCESS, "Leads imported successfully.");
    } catch (e) {
      notify(
        DANGER,
        "Import leads failed",
        e?.response?.data?.reason || "Something went wrong. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (file) => {
    setFile(file);
  };

  return (
    <div className={styles.container}>
      <div className={styles.fileSample}>
        <a
          download
          href="https://fromaz-secondary-production-general-74017846.s3.us-east-2.amazonaws.com/public/static/import_leads_sample_csv.csv"
        >
          Click here to download CSV file sample.
        </a>
      </div>
      <FileUploader
        classes={styles["dropArea"]}
        handleChange={handleChange}
        name="file"
        types={["CSV"]}
        hoverTitle="Drop here"
      />
      <p className={styles.fileLabel}>
        {file ? `Uploaded file: ${file.name}` : "No files uploaded"}
      </p>
      <div className={styles.actions}>
        <button className={styles["spinning-button"]} onClick={() => handleCloseModal()}>
          <span>Cancel</span>
        </button>
        <button
          className={styles["spinning-button"]}
          disabled={isLoading || !file}
          onClick={handleUploadFile}
        >
          <span>{isLoading ? "Processing..." : "Import file"}</span>
          {isLoading && (
            <div className={styles["spinning-icon-background"]}>
              <Spinner size="sm" color="primary" />
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default ImportLeads;
