import React from "react";

import styles from "./Notifications.module.css";
import cn from "classnames";

import {Icon} from "UI";

const Notifications = ({type, message, description}) => {
  return (
    <div className={cn(styles["notification-container"], styles[`${type}-container`])}>
      <div className={cn(styles["notification-icon-container"], styles[`${type}-icon`])}>
        <Icon icon={type ? `${type}` : "check"} />
      </div>
      <div className={styles["messages-container"]}>
        {!!message ? <span className={styles.message}>{message}</span> : null}
        {!!description ? <span className={styles.description}>{description}</span> : null}
      </div>
      <div className={styles["close-button-container"]}>
        <Icon icon="close-small" />
      </div>
    </div>
  );
};

export default Notifications;
