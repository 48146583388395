import React, {useState, useMemo} from "react";
import Link from "next/link";
import {withRedux} from "@/redux/reduxUtils";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {initialize} from "@/services/CallClient";
import {checkUserPermission} from "@/util/authUtils";
import {getError} from "@/util/formUtils";
import InputField from "@/components/InputField/InputField";
import twilioAPI from "@/api/twilio/twilio";
import {
  ModalDialog,
  notify,
  DANGER,
  SUCCESS,
  CancelButton,
  SaveButton,
  LoadingButton,
} from "@/components/UI";
import {parseServerErrors} from "@/util/formUtils";
import {handleErrors} from "@/util/helperFunctions";

import styles from "./Dialogs.module.css";

const Connect = ({isOpen, closeDialog, onSuccess}) => {
  const companyId = useSelector((state) => state.company?.current?.id);
  const [isLoading, setIsLoading] = useState(false);
  const [mappedErrors, setMappedErrors] = useState({});
  const {register: form, handleSubmit, errors, getValues, setValue, control} = useForm();

  const formInputFields = [
    {
      type: "text",
      label: "Account SID",
      name: "twilio_account_sid",
      validation: {required: true},
      placeholder: "Set",
      className: styles.input,
    },
    {
      type: "text",
      label: "Auth Token",
      name: "twilio_auth_token",
      validation: {required: true},
      placeholder: "Set",
      className: styles.input,
    },
  ];

  const userRole = useSelector((state) => state.user?.role) ?? null;
  const hasPermission = useMemo(() => {
    return checkUserPermission(userRole, "communication_customer_calls_enabled");
  }, [userRole]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await twilioAPI().connect(companyId, data);
      notify(SUCCESS, "Created 👍", `New twilio connection successfully created`);
      if (hasPermission) {
        initialize([companyId]);
      }
      setIsLoading(false);
      onSuccess?.();
    } catch (err) {
      setIsLoading(false);
      setMappedErrors(parseServerErrors(err));
      handleErrors(err, "Please fill all required fields correctly", DANGER);
    }
  };

  return (
    <ModalDialog isOpen={isOpen} toggle={closeDialog} title="Connect to Twilio">
      <div className={styles["form-wrapper"]}>
        <p>
          To access and leverage our suite of services seamlessly through Twilio, kindly provide
          your Twilio SID and authentication token. This will enable us to utilize your Twilio
          account effectively.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formInputFields.map((field, index) => {
            return (
              <div key={field.name} className={styles["form-field"]}>
                <InputField
                  fieldType={field.type}
                  getValues={getValues}
                  setValue={setValue}
                  form={form}
                  control={control}
                  error={getError(errors, field.name)}
                  {...field}
                />
              </div>
            );
          })}
          <Link href="/twilio-tutorial" target="_blank">
            No Twilio account yet? Explore our tutorial to begin.
          </Link>
          <div className={styles["buttons-container"]}>
            <CancelButton
              onClick={(e) => {
                closeDialog();
                e.preventDefault();
              }}
            />
            {!isLoading ? (
              <SaveButton />
            ) : (
              <button
                onClick={(e) => {
                  e.preventDefault();
                }}
                className={styles["create-btn"]}
              >
                <div className={styles.loader}>
                  <div className={styles.loadingBtn}>
                    <LoadingButton />
                  </div>
                  <span>Creating connection</span>
                </div>
              </button>
            )}
          </div>
        </form>
      </div>
    </ModalDialog>
  );
};

export default withRedux(Connect);
