import React from "react";
import {withRedux} from "@/redux/reduxUtils";
import {useSelector} from "react-redux";
import twilioAPI from "@/api/twilio/twilio";
import {ModalDialog, CancelButton, SaveButton, notify, SUCCESS, DANGER} from "@/components/UI";
import {handleErrors} from "@/util/helperFunctions";
import styles from "./Dialogs.module.css";

const Disconnect = ({isOpen, closeDialog, onSuccess}) => {
  const company = useSelector((state) => state.company);

  const handleDelete = async () => {
    try {
      await twilioAPI().deleteConnection(company?.current?.id);
      notify(SUCCESS, "Deleted 👍", `Deleted twilio connection successfully`);
      onSuccess?.();
    } catch (err) {
      handleErrors(err, "Something went wrong", DANGER);
    }
  };

  return (
    <ModalDialog isOpen={isOpen} toggle={closeDialog} title="Disconnect">
      <div className={styles["form-wrapper"]}>
        <p>Are you sure you want to disconnect from twilio?</p>
        <p>
          Disconnecting Twilio will prevent you from both sending and receiving phone calls, text
          messages, using surveys and your phone numbers will be deleted.
        </p>
        <div className={styles["buttons-container"]}>
          <CancelButton
            onClick={(e) => {
              closeDialog();
              e.preventDefault();
            }}
          />
          <SaveButton onClick={handleDelete} className={styles["create-btn"]}>
            Disconnect
          </SaveButton>
        </div>
      </div>
    </ModalDialog>
  );
};

export default withRedux(Disconnect);
