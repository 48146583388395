import React from "react";
import cn from "classnames";

import styles from "./DeleteButton.module.css";

const DeleteButton = ({children, className, ...props}) => {
  return (
    <button {...props} className={cn(styles.add, className)}>
      {children}
    </button>
  );
};

export default DeleteButton;
