import axios from "axios";
import getConfig from "next/config";
import cookies from "next-cookies";
import {setStore} from "@/components/Twilio";
import imageCompression from "browser-image-compression";

const {publicRuntimeConfig} = getConfig();

let googleApiClient;

const createApiClient = () =>
  axios.create({
    baseURL: publicRuntimeConfig.backendApiHost,
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
  });

/**
 * Get axios client instance
 * @param ctx {FromazPageContext} Used in getInitialProps for SSR
 * @returns {AxiosInstance}
 */
export const getApiClient = (ctx) => {
  // eslint-disable-next-line no-shadow
  const apiClient = createApiClient();
  apiClient.interceptors.request.use(
    (request) => {
      if (ctx && ctx.req) {
        request.headers.Cookie = ctx.req.headers.cookie;
        const csrfCookie = cookies(ctx).csrftoken;
        if (csrfCookie) {
          request.headers["X-CSRFToken"] = csrfCookie;
        }
      }
      return request;
    },
    (error) => {
      log(error);
      throw error;
    }
  );
  apiClient.interceptors.response.use(
    (response) => {
      if (ctx && ctx.res && response.headers["Set-Cookie"]) {
        ctx.res.setHeader("Set-Cookie", response.headers["Set-Cookie"]);
      }
      return response;
    },
    (error) => {
      if (error.response?.data?.error === "noTwilioToken") {
        setStore("connect");
      }
      if (error.response?.data?.error === "noTwilioNumber") {
        setStore("number");
      }
      log(`HTTP Failed: ${error.request?.method} ${error.request?.path}`);
      if (error.response) {
        log(error.response.data);
      } else {
        log(error);
      }
      throw error;
    }
  );
  return apiClient;
};

function log(message) {
  if (process.env.NODE_ENV !== "production") {
    // console.log(message);
  }
}

const createGoogleApiClient = () =>
  axios.create({
    baseURL: "https://maps.googleapis.com/maps/api",
    withCredentials: false,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
  });

/**
 * Get axios client instance
 * @param ctx {FromazPageContext} Used in getInitialProps for SSR
 * @returns {AxiosInstance}
 */
export const getGoogleApiClient = (ctx) => {
  if (ctx && ctx.req) {
    const googleApiClient = createGoogleApiClient();
    return googleApiClient;
  }
  if (!googleApiClient) {
    googleApiClient = createGoogleApiClient();
  }
  return googleApiClient;
};

const createMockApiClient = () =>
  axios.create({
    baseURL: "localhost:8001/api",
    withCredentials: false,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
  });

/**
 * Get axios mock client instance
 * @param ctx {FromazPageContext} Used in getInitialProps for SSR
 * @returns {AxiosInstance}
 */
export const mockApiClient = (ctx) => {
  if (ctx && ctx.req) {
    const mockApiClient = createMockApiClient();
    return mockApiClient;
  }
  if (!mockApiClient) {
    const mockApiClient = createMockApiClient();
  }
  return mockApiClient;
};

export const optimizeAndConvertToPng = async (file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 700,
    useWebWorker: true,
    maxIteration: 10,
    exifOrientation: null,
    onProgress: function (progress) {
      console.log(`Compression progress: ${progress}%`);
    },
  };

  try {
    const compressedFile = await imageCompression(file, options);

    // Convert the compressed file to PNG using canvas
    const pngFile = await convertToPng(compressedFile);
    return pngFile;
  } catch (error) {
    console.error("Error while compressing the image:", error);
    throw error;
  }
};

const convertToPng = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(
          (blob) => {
            resolve(new File([blob], file.name.replace(/\.\w+$/, ".png"), {type: "image/png"}));
          },
          "image/png",
          1
        ); // Adjust the quality if needed
      };
    };
    reader.onerror = (error) => reject(error);
  });
};
