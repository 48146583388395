import React from "react";
import cn from "classnames";
import styles from "./CancelButton.module.css";

const CancelButton = (props) => (
  <button
    type={"button"}
    className={cn(styles.button, props.className)}
    onClick={props.onClick}
    style={{backgroundColor: props.bgColor, width: props.width}}
  >
    <span className={styles.text} style={{color: props.color}}>
      {props.children ? props.children : props.text ? props.text : "Cancel"}
    </span>
  </button>
);

export default CancelButton;
