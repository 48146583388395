import React from "react";
import cx from "classnames";

import styles from "./Tabs.module.css";

const Tabs = ({options, active, setActive, className}) => {
  return (
    <div className={cx(styles.tabs, className)}>
      {options.map((option, index) => {
        return (
          <Tab
            key={`${option}--${index}`}
            active={active == index}
            option={option}
            onClick={() => setActive(index)}
          />
        );
      })}
    </div>
  );
};

const Tab = ({option, active, onClick}) => {
  return (
    <button className={cx(styles.tab, {[styles.active]: active})} onClick={onClick}>
      {option}
    </button>
  );
};

export default Tabs;
