import React from "react";
import cn from "classnames";
import {Modal} from "reactstrap";
import styles from "./ModalDialog.module.css";

const ModalDialog = ({
  isOpen,
  toggle,
  wrapperClassName,
  contentClassName,
  headerClassName,
  title,
  noPadding,
  noHeader,
  notCentered,
  autoFocus = true,
  backdrop = true,
  children,
}) => {
  return !isOpen ? null : (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={cn(styles.modalWrapper, wrapperClassName)}
      contentClassName={cn(styles.modalContent, contentClassName)}
      centered={!notCentered}
      autoFocus={autoFocus}
      backdrop={backdrop}
    >
      {!noHeader && (
        <div className={cn(styles.header, headerClassName)}>
          <p className={styles.title}>{title}</p>
          <button onClick={toggle} className={styles.close}>
            &#10005;
          </button>
        </div>
      )}
      <div className={cn(styles.bodyClassName, {[styles.noPadding]: noPadding})}>{children}</div>
    </Modal>
  );
};

export default ModalDialog;
