import React from "react";
import cn from "classnames";
import {UncontrolledTooltip} from "reactstrap";
import {Icon} from "@/components/UI";
import styles from "./InfoTooltip.module.css";

const InfoTooltip = ({id, iconName, description, className, width, height, ...rest}) => {
  return (
    <div className={cn(styles.container, className)} {...rest}>
      <Icon
        id={id}
        icon={iconName || "information-icon"}
        width={width || "21px"}
        height={height || "21px"}
      />
      <UncontrolledTooltip target={id}>{description}</UncontrolledTooltip>
    </div>
  );
};

export default InfoTooltip;
