import React, {useEffect, useState} from "react";
import moment from "moment";
import {IconButton, TextInput, DatePicker} from "UI";

import styles from "./FormDateInput.module.css";
import Icon from "UI/Icon/Icon";

const FormDateInput = (props) => {
  const {
    error,
    label,
    placeholder,
    name,
    form,
    defaultValue,
    validation,
    disabled,
    className,
    onChange,
    minDate,
    maxDate,
    control,
    forRevenue,
  } = props;

  const [value, setValue] = useState(defaultValue ? moment.utc(defaultValue)._d : "");
  const [showPicker, setShowPicker] = useState(false);

  const handleValueChange = (val) => {
    setValue(moment(val).toDate());
    setShowPicker(false);
    if (onChange) {
      onChange(val);
    }
  };

  useEffect(() => {
    setValue(defaultValue ? moment.utc(defaultValue)._d : "");
  }, [defaultValue]);

  let labelCSSStyle = "label";
  if (!label) {
    labelCSSStyle = "blank-label";
  }

  return (
    <>
      <div className={styles.backdrop} style={!showPicker ? {display: "none"} : {}}></div>
      <div>
        <label className={styles[labelCSSStyle]}>
          {label}
          {validation && validation.required && <span className="text-danger">*</span>}
        </label>
        <div
          className={styles["input-box"]}
          onClick={() => setShowPicker((prev) => (disabled ? false : !prev))}
        >
          <input
            className="d-none"
            name={name}
            ref={form(validation)}
            value={value}
            onChange={() => {}}
            type="text"
          />
          <TextInput
            name={name}
            control={control}
            disabled={disabled}
            disabledInput={true}
            className={className}
            placeholder={value ? "" : placeholder}
          />
          <div className={styles["selected-value"]}>
            {value && moment(value).format("DD MMM YYYY")}
          </div>
          {value ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setValue("");
              }}
              className={styles.remove}
            >
              &times;
            </button>
          ) : !forRevenue ? (
            <IconButton
              icon="close-nav"
              style={{
                position: "absolute",
                right: "20px",
                top: "15px",
                transform: "rotate(-90deg)",
              }}
            />
          ) : (
            <IconButton
              icon="calendar2"
              style={{
                position: "absolute",
                right: "15px",
                top: "10px",
              }}
            />
          )}
          <div className={styles["container"]} onClick={(e) => e.stopPropagation()}>
            {showPicker && (
              <div className={styles[`close-picker`]}>
                <Icon className={styles.close} icon="close" onClick={() => setShowPicker(false)} />
              </div>
            )}
            <DatePicker
              setValue={handleValueChange}
              value={value}
              showPicker={showPicker}
              minDate={minDate}
              maxDate={maxDate}
              disabled={disabled}
              removeBox
              hideLabel
            />
          </div>
          {error && (
            <small style={{color: "red", marginTop: "3px"}}>
              {typeof error == "object" ? error.message : error}
            </small>
          )}
        </div>
      </div>
    </>
  );
};

export default FormDateInput;
