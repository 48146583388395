export const defaultFieldsMapping = {
  first_name: "first_name",
  last_name: "last_name",
  score: "score",
  size: "size",
  status: "status",
  reference: "reference",
  source: "source",
  booking_expectation: "booking_expectation",
  expected_book_date: "expected_book_date",
  delivery_date_from: "delivery_date_from",
  delivery_date_to: "delivery_date_to",
  moving_to_addr1: "moving_to_addr1",
  moving_to_addr2: "moving_to_addr2",
  moving_to_addr3: "moving_to_addr3",
  moving_to_city: "moving_to_city",
  moving_to_region: "moving_to_region",
  moving_to_zipcode: "moving_to_zipcode",
  moving_to_country_code: "moving_to_country_code",
  moving_to_state: "moving_to_state",
  moving_from_addr1: "moving_from_addr1",
  moving_from_addr2: "moving_from_addr2",
  moving_from_addr3: "moving_from_addr3",
  moving_from_city: "moving_from_city",
  moving_from_region: "moving_from_region",
  moving_from_zipcode: "moving_from_zipcode",
  moving_from_country_code: "moving_from_country_code",
  moving_from_state: "moving_from_state",
  stage: "stage",
  phone1_name: "phone1_name",
  phone1_phone: "phone1_phone",
  phone2_name: "phone2_name",
  phone2_phone: "phone2_phone",
  mail1_name: "mail1_name",
  mail1_email: "mail1_email",
  mail2_name: "mail2_name",
  mail2_email: "mail2_email",
};
