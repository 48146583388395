import React from "react";
import styles from "./Checkbox.module.css";
import cn from "classnames";

import {Icon} from "UI";

const Checkbox = ({onChange, value, className, label, classNameWrapper, disabled}) => {
  return (
    <div
      className={cn(styles["checkbox-wrapper"], classNameWrapper, {
        [styles["checkbox-wrapper-disabled"]]: disabled,
      })}
    >
      {label && <span className={styles["check-mark-label"]}>{label}</span>}
      <button
        disabled={disabled}
        className={cn({[styles["checkbox-checked"]]: value, [styles.checkbox]: !value}, className, {
          [styles["checkbox-disabled"]]: disabled,
        })}
        onClick={(e) => {
          e.preventDefault();
          onChange(!value);
        }}
      >
        <div className={styles["check-mark-wrapper"]}>
          <Icon
            icon="check-mark-white"
            className={cn({
              [styles["check-mark-checked"]]: value,
              [styles["check-mark"]]: !value,
            })}
          />
        </div>
      </button>
    </div>
  );
};

export default Checkbox;
