import {getSafeDeep} from "@/util/helperFunctions";

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const isJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const lastInArray = (arr) => {
  return arr[arr.length - 1];
};

export function parseServerErrors(errors) {
  if (
    ["validationError", "invalidPhone"].includes(
      errors && errors.response && errors.response.data.error
    )
  ) {
    console.error(errors);
    return {};
  }
  if (getSafeDeep(errors, "response.data", false)) {
    if (
      ["validationError", "invalidPhone"].includes(
        errors && errors.response ? errors.response.data.error : ""
      )
    ) {
      return {};
    }

    return parseErrors(errors);
  }
}

export const parseErrors = (errors) => {
  if (getSafeDeep(errors, "response.data", false)) {
    // console.log(errors.response)
    const obj = errors.response.data.reason;
    const errs = {reason: errors.response.data.reason};
    return errs;
  }
};

export function getDeep(object, path) {
  if (object[path]) return object[path];

  let obj = object;

  const props = path.split(".");

  for (let i = 0; i < props.length; i++) {
    if (obj[props[i]]) {
      obj = obj[props[i]];
    } else {
      return undefined;
    }
  }

  return obj;
}

export function parseFormErrors(errors) {
  const errs = {};

  Object.entries(errors).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((err, index) => {
        if (err) {
          Object.keys(err).forEach((k) => {
            errs[`${key}.${index}.${k}`] = "This field is required";
          });
        }
      });
    } else if (!value?.ref) {
      Object.keys(value).forEach((k) => {
        errs[`${key}.${k}`] = "This field is required";
      });
    } else {
      errs[key] = "This field is required";
    }
  });

  return errs;
}

export function removeEmptyFields(data) {
  if (data)
    Object.keys(data).forEach((key) => {
      if (
        data[key] == "" ||
        data[key] == undefined ||
        data[key] == "Invalid date" ||
        data[key] == 0
      ) {
        console.log(key);
        return data[key] == null;
      }
    });
}

export function setEmptyToNull(data) {
  if (data)
    Object.keys(data).forEach((key) => {
      if (
        data[key] == "" ||
        data[key] == undefined ||
        data[key] == "Invalid date" ||
        data[key] == 0
      ) {
        data[key] = null;
      }
    });
}

export function getError(errors, name) {
  //field
  if (errors[name]) return errors[name];

  //array
  let arryErrs = {};
  Object.keys(errors).forEach((key) => {
    if (key.startsWith(name) && !isNaN(key.substr(name.length + 1) * 1)) {
      arryErrs[key.substr(name.length + 1)] = errors[key];
    }
  });
  if (Object.keys(arryErrs).length > 0) {
    return arryErrs;
  }

  //object
  let err = "";
  Object.keys(errors).forEach((key) => {
    if (key.endsWith(`.${name}`)) {
      err = errors[key];
    }
  });
  if (err != "") return err;

  //array in object
  let arrInObjectErrs = {};
  Object.keys(errors).forEach((key) => {
    if (
      key.includes(`.${name}.`) &&
      !isNaN(key.substr(key.indexOf(`.${name}.`) + `.${name}.`.length) * 1)
    ) {
      arrInObjectErrs[key.substr(key.indexOf(`.${name}.`) + `.${name}.`.length)] = errors[key];
    }
  });
  if (Object.keys(arrInObjectErrs).length > 0) {
    return arrInObjectErrs;
  }

  return "";
}
