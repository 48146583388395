import React from "react";

import styles from "./Spinner.module.css";

import cx from "classnames";

const Spinner = ({isLoading, noBackdrop, centered, maxedHeight}) => {
  if (!isLoading) return null;

  return (
    <div
      className={cx({
        [styles.backdrop]: !noBackdrop,
        [styles.centered]: centered,
        [styles["maxed-height"]]: maxedHeight,
      })}
    >
      <div className={styles.spinner}>{/* spinner */}</div>
    </div>
  );
};

export default Spinner;
