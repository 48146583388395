export const DatePickerStyles = `
  .DayPicker{
    outline: none;
    background: #fff;
    width: 240px;
    padding: 16px 0;
    border-radius: 12px 0;
    box-shadow: 0 6px 16px 0 rgba(65,82,125,0.16);
    line-height: 0;
  }
  .DayPicker-Month, .DayPicker-Day, .DayPicker-Caption, .DayPicker-Body, .DayPicker-Weekdays, .DayPicker-Weekday{
    display: block;
  }
  .DayPicker-WeekdaysRow, .DayPicker-Week{
    display: flex;
    justify-content: space-between;
  }
  .DayPicker-Day, .DayPicker-Weekday{
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    width: 20px;
    height: 20px;
    color: #7E8A95;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
  .DayPicker-Month{
    margin: 16px;
    margin-bottom: 6px;
    flex: 1;
  }
  .DayPicker-Weekdays{
    margin: 0
  }
  .DayPicker-Week{
    margin-bottom: 12px;
  }
  .DayPicker-Week:last-child{
    margin-bottom: 0;
  }
  .DayPicker-WeekdaysRow{
    margin-bottom: 14px;
  }
  .DayPicker-WeekdaysRow abbr{
    cursor: initial;
  }
  .DayPicker-Day--disabled{
    color:#d3d5d8;
  }

  
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
    color: #fff;
    background-color: var(--primary-color);
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover{
    background-color: var(--primary-color);
  }
  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: rgba(220, 220, 220, 0.3);
  }
  .DayPicker-Day--outside{
    opacity: 0.4;
  }
  .DayPicker-Footer{
    padding-top: 0;
    display: flex;
    justify-content: center;
    line-height: 2;
  }
  .DayPicker-wrapper{
    outline: none;
  }
  .DayPicker-Day:hover{
    cursor: pointer;
  }

  .DayPicker-Day--disabled:hover{
    cursor: auto;
  }
`;
