import React from "react";
import {ModalDialog, CancelButton, SaveButton} from "UI";
import styles from "./ConfirmationModal.module.css";

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  cancelHandler,
  proceedHandler,
  confirmButtonText,
  cancelButtonText,
  title,
  text,
  children,
  contentWidth,
}) => {
  const handleCancel = () => {
    cancelHandler?.();
    setIsOpen(false);
  };

  const handleSave = async () => {
    await proceedHandler();
    setIsOpen(false);
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      toggle={() => {
        cancelHandler?.();
        setIsOpen(false);
      }}
      title={title || "Confirm action"}
    >
      <div className={styles["content"]} {...(contentWidth ? {style: {width: contentWidth}} : {})}>
        <span className={styles["text"]}>{text || children}</span>
        <div className={styles["buttons"]}>
          <CancelButton text={cancelButtonText} onClick={handleCancel} />
          <SaveButton text={confirmButtonText} onClick={handleSave} />
        </div>
      </div>
    </ModalDialog>
  );
};

export default ConfirmationModal;
