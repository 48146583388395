import React, {useState} from "react";

import styles from "./ToggleButton.module.css";
import cn from "classnames";

import {Icon} from "UI";

const ToggleButton = (props) => {
  const {
    value, //boolean
    toggleActive, //toggle value
    activeIcon1,
    nonActiveIcon1,
    activeIcon2,
    nonActiveIcon2,
    className,
  } = props;
  const [active, setActive] = useState(0);

  return (
    <div className={cn(styles["button-container"], className)}>
      <div
        className={cn(styles["icon-container"], active == 0 ? styles.active : null)}
        onClick={() => {
          if (active !== 0) {
            toggleActive(!value);
          }
          setActive(0);
        }}
      >
        <Icon icon={active == 0 ? activeIcon1 : nonActiveIcon1} />
      </div>
      <div
        className={cn(styles["icon-container"], active == 1 ? styles.active : null)}
        onClick={() => {
          if (active !== 1) {
            toggleActive(!value);
          }
          setActive(1);
        }}
      >
        <Icon icon={active == 0 ? activeIcon2 : nonActiveIcon2} />
      </div>
    </div>
  );
};

export default ToggleButton;
