import React, {useState} from "react";
import cn from "classnames";
import styles from "./WeekPicker.module.css";
import {WeekPickerStyles} from "./WeekPicker.styles";
import moment from "moment";
import DayPicker from "react-day-picker";

import Head from "next/head";

import {IconButton} from "UI";

const Navbar = (props) => {
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={props.removeBox ? {padding: "0 16px 0 16px"} : {padding: "16px"}}
    >
      <IconButton
        onClick={() => props.onPreviousClick()}
        icon="close-nav"
        style={{
          lineHeight: 0,
        }}
      />
      <p className={styles["date"]}>{moment(props.month).format("MMM YYYY")}</p>
      <IconButton
        onClick={() => props.onNextClick()}
        icon="close-nav"
        style={{
          lineHeight: 0,
          transform: "rotate(180deg)",
        }}
      />
    </div>
  );
};

const Caption = (_) => {
  return null;
};

function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, "days").toDate());
  }
  return days;
}

function getWeekRange(date) {
  return {
    from: moment(date).startOf("week").toDate(),
    to: moment(date).endOf("week").toDate(),
  };
}

const WeekPicker = (props) => {
  const {showPicker, removeBox, dateRange, setDateRange, className} = props;
  const [hoverRange, setHoverRange] = useState();
  const [selectedDays, setSelectedDays] = useState([]);
  const daysAreSelected = selectedDays.length > 0;

  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0], //dateRange
      to: selectedDays[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0], //dateRange
    selectedRangeEnd: daysAreSelected && selectedDays[6],
  };

  const handleDayChange = (date) => {
    setSelectedDays(getWeekDays(getWeekRange(date).from));
    setDateRange(getWeekDays(getWeekRange(date).from));
  };

  const handleDayEnter = (date) => {
    setHoverRange(getWeekRange(date));
  };

  const handleDayLeave = () => {
    setHoverRange(undefined);
  };

  const handleWeekClick = (weekNumber, days, e) => {
    setSelectedDays(days);
    setDateRange(days);
  };

  return (
    <>
      <Head>
        <style>{WeekPickerStyles}</style>
        {removeBox && (
          <style>{`
            .DayPicker{
              outline: none;
              background: transparent;
              height: auto;
              border-radius: 0;
              box-shadow: none;
            }
            
          `}</style>
        )}
      </Head>
      <div
        className={cn(styles["input-box"], className)}
        style={!showPicker ? {display: "none"} : {}}
      >
        {removeBox && <p className={styles.title}>Pick Date</p>}
        <div className="week">
          <DayPicker
            firstDayOfWeek={1}
            showOutsideDays
            captionElement={<Caption />}
            navbarElement={<Navbar removeBox />}
            modifiers={modifiers}
            selectedDays={selectedDays}
            onDayClick={handleDayChange}
            onDayMouseEnter={handleDayEnter}
            onDayMouseLeave={handleDayLeave}
            onWeekClick={handleWeekClick}
          />
        </div>
      </div>
    </>
  );
};

export default WeekPicker;
