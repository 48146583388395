//DayPicker: min-height: 270px;

export const WeekPickerStyles = `
.DayPicker{
  outline: none;
  background: #fff;
  width: 240px;
  padding: 12px 0;
  border-radius: 16px;
  box-shadow: 0 6px 16px 0 rgba(65,82,125,0.16);
  line-height: 0;
}
.DayPicker-Month, .DayPicker-Day, .DayPicker-Caption, .DayPicker-Body, .DayPicker-Weekdays, .DayPicker-Weekday{
  display: block;
  padding-bottom: 0px;
}
.DayPicker-WeekdaysRow, .DayPicker-Week{
  display: flex;
  justify-content: flex-start;
}
.DayPicker-Day, .DayPicker-Weekday{
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  width: 30px;
  height: 20px;
  color: #7E8A95;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.DayPicker-Month{
  margin: 16px 16px 6px;
  flex: 1;
}
.DayPicker-Weekdays{
  margin: 0
}
.DayPicker-Week{
  margin-bottom: 12px;
}
.DayPicker-Week:last-child{
  margin-bottom: 0;
}
.DayPicker-WeekdaysRow{
  margin-bottom: 14px;
}
.DayPicker-WeekdaysRow abbr{
  cursor: initial;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
  
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover{
}
.DayPicker-Day--selected{
  border-radius: 0;
  color: #7e8a95;
  background-color: rgba(89, 107, 255, 0.06);
}

.DayPicker-Day--start{
  color: white;
  background-color: var(--primary-color);
  width:22px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 50%;
  position: relative;
}
.DayPicker-Day--start:after{
  content: "";
  display: block;
  width: 21px;
  height: 20px;
  background: rgba(89, 107, 255, 0.06);
  position: absolute;
  left: 5px;
}
.DayPicker-Day--start:focus{
  outline: none;
}
.DayPicker-Day--end{
  color: white;
  background-color: var(--primary-color);
  width:22px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 50%;
  position: relative;
}
.DayPicker-Day--end:after{
  content: "";
  display: block;
  width: 21px;
  height: 20px;
  background: rgba(89, 107, 255, 0.06);
  position: absolute;
  right: 5px;
}
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: rgba(220, 220, 220, 0.3);
  width:22px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 50%;
}
.DayPicker-Day--outside{
  opacity: 0.4;
}
.DayPicker-Footer{
  padding-top: 0;
  display: flex;
  justify-content: center;
  line-height: 2;
}
.DayPicker-wrapper{
  outline: none;
}
.DayPicker-Day:hover{
  cursor: pointer;
}

.DayPicker-Day--selectedRangeStart {
  background-color: var(--primary-color);
  color: white;
  width:22px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 50%;
  position: relative;
}

.DayPicker-Day--selectedRangeStart:after {
  content: "";
  display: block;
  width: 21px;
  height: 20px;
  background: rgba(89, 107, 255, 0.06);
  position: absolute;
  left: 5px;
}

.DayPicker-Day--selectedRangeEnd {
  background-color: var(--primary-color);
  color: white;
  width:22px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 50%;
  position: relative;
}

.DayPicker-Day--selectedRangeEnd:after {
  content: "";
  display: block;
  width: 21px;
  height: 20px;
  background: rgba(89, 107, 255, 0.06);
  position: absolute;
  right: 5px;
}
`;
