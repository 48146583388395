import React, {useState, useEffect, forwardRef} from "react";
import cn from "classnames";
import useDebounce from "@/util/useDebounce";
import {useSelector} from "react-redux";

import {getRenderComponent} from "./Render/Render";
import {TextInput, IconButton} from "UI";
import {getData} from "country-list";
const {listTimeZones} = require("timezone-support");
const timeZonesFiltered = listTimeZones().filter(
  (tz) => tz.includes("/") && !tz.includes("etc") && !tz.includes("Etc")
);

import styles from "./SelectInput.module.css";
const SelectInput = (props, ref) => {
  const {
    placeholder,
    options: propsOptions,
    setValue,
    value,
    loadOptions,
    format,
    multi,
    renderLabel,
    defaultValue,
    country,
    timeZone,
    moving,
    onFocus,
    stage,
    source,
    containerClassName,
    className,
    selectedValueClassName,
    optionsBoxClassName,
    optionClassName,
    error,
    task,
    simpleDefaultValue,
    readOnly,
    type = "text",
    disabled = false,
  } = props;
  const moveSize = useSelector((state) => state.types.moveSizes || []);
  const leadStages = useSelector((state) => state.types.leadStages || []);
  const leadSources = useSelector((state) => state.types.leadSources || []);
  const [showOptions, setShowOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(propsOptions || []);
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 1000);
  const handleLoadOptions = async () => {
    try {
      setIsLoading(true);
      const {
        data: {results: data},
      } = await loadOptions(search);
      setOptions(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  // trigger on select opened
  useEffect(() => {
    if (loadOptions && showOptions) {
      handleLoadOptions();
    }
  }, [showOptions]);
  // trigger on search changed
  useEffect(() => {
    if (debounceSearch) {
      handleLoadOptions();
    }
  }, [debounceSearch]);
  useEffect(() => {
    if (!loadOptions && propsOptions) {
      setOptions(propsOptions);
    }
  }, [propsOptions]);
  const handleSearch = (e) => {
    const val = e.target.value;
    setSearch(val);
  };
  const Component = getRenderComponent(format);
  const removeItem = (e) => {
    e.preventDefault();
    setValue("");
  };

  useEffect(() => {
    if (defaultValue && simpleDefaultValue) setValue(defaultValue);
    else if (defaultValue && format === "user") setValue(defaultValue);
    else if (timeZone == true && defaultValue) {
      let timeZones = timeZonesFiltered;
      let targetIndex = timeZones.findIndex((x) => x == defaultValue);
      if (targetIndex > -1)
        setValue({
          value: timeZones[targetIndex],
          label: timeZones[targetIndex],
        });
    } else if (defaultValue && format === "lead" && task) setValue(defaultValue);
    else if (source === true && defaultValue) {
      let targetIndex = leadSources.findIndex((x) => x.id === defaultValue.id);
      if (targetIndex > -1)
        setValue({
          value: leadSources[targetIndex],
          label: leadSources[targetIndex].name,
        });
    } else if (moving === true && (defaultValue || defaultValue === 0)) {
      let targetIndex = moveSize.findIndex((x) => x.id === defaultValue);
      if (targetIndex > -1)
        setValue({
          value: moveSize[targetIndex].code,
          label: moveSize[targetIndex].name,
        });
    } else if (stage === true && defaultValue) {
      let targetIndex = leadStages.findIndex((x) => x.id === defaultValue);
      if (targetIndex > -1)
        setValue({
          value: leadStages[targetIndex].id,
          label: leadStages[targetIndex].name,
        });
    } else if (defaultValue && format === "text" && !country)
      setValue({color: defaultValue.color, label: defaultValue.name});
    else if (country === true && defaultValue) {
      let countrys = getData();
      let targetIndex = countrys.findIndex((x) => x.code === defaultValue);
      if (targetIndex > -1)
        setValue({
          value: countrys[targetIndex].code,
          label: countrys[targetIndex].name,
        });
    } else if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <div
        className={styles.backdrop}
        style={!showOptions ? {display: "none"} : {}}
        onClick={() => setShowOptions(false)}
      ></div>
      <div
        className={cn(containerClassName, styles["input-box"])}
        onClick={() => !disabled && setShowOptions((prev) => !prev)}
      >
        <TextInput
          className={cn({[styles["hide-input-text"]]: !showOptions}, className)}
          error={error}
          onChange={handleSearch}
          value={search}
          onFocus={onFocus}
          placeholder={value || isLoading ? "" : placeholder}
          readOnly={readOnly}
          type={type}
        />
        <div ref={ref} className={cn(selectedValueClassName, styles["selected-value"])}>
          {isLoading && !search ? (
            <span>Loading...</span>
          ) : value && (!search || !showOptions) ? (
            renderLabel ? (
              renderLabel(value, removeItem)
            ) : (
              value.label
            )
          ) : null}
        </div>
        {!disabled && (
          <IconButton
            icon="close-nav"
            style={{
              position: "absolute",
              right: "20px",
              top: "15px",
              transform: showOptions ? "rotate(90deg)" : "rotate(-90deg)",
            }}
          />
        )}
        {showOptions && (
          <div className={cn(styles["options-box"], optionsBoxClassName)}>
            {options.length > 0 ? (
              options
                .filter((item) =>
                  item.label ? item.label.toLowerCase().includes(search.toLowerCase()) : true
                )
                .map((option, i) => {
                  return (
                    <Component
                      key={i}
                      option={option}
                      setValue={setValue}
                      value={value}
                      className={optionClassName}
                    />
                  );
                })
            ) : (
              <small className="d-block text-center pt-2">No Results</small>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default forwardRef(SelectInput);
