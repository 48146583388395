import React, {useState} from "react";
import cn from "classnames";

import {Icon} from "UI";

import styles from "./FileInput.module.css";

const FileInput = ({form, className, options, defaultValue, ...props}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const {icon = "plus-white"} = options;

  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const onFileUpload = () => {
    const formData = new FormData();
    formData.append("legal", selectedFile, selectedFile?.name);
  };

  return (
    <div className={cn(styles["wrapper"], styles["className"])}>
      {defaultValue ? (
        <button
          className={cn(styles["btn"], {
            [styles["btn--uploaded"]]: defaultValue,
          })}
        >
          <a className={styles.download} href={defaultValue} download>
            {selectedFile?.name || "terms_and_conditions.txt"}
          </a>
        </button>
      ) : (
        <button
          className={cn(styles["btn"], {
            [styles["btn--uploaded"]]: selectedFile?.name,
          })}
        >
          {selectedFile?.name || props.placeholder}
        </button>
      )}
      <div className={styles["icon"]} onClick={onFileUpload}>
        <Icon icon={icon == "default" ? "plus-white" : icon} />
      </div>
      <input ref={form} className={cn(styles.input)} onChange={onFileChange} {...props} />
    </div>
  );
};

export default FileInput;
