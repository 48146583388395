import {getApiClient} from "@/api/util";
import qs from "qs";

export default (ctx) => ({
  // token for intialization device
  getToken: (company_id) => getApiClient(ctx).post(`/company/${company_id}/calls/token/`),
  // list for all calls, busy, completed, missed
  currentCalls: (limit, offset, only = "all", filters) =>
    getApiClient(ctx).get(`/current-calls/`, {
      params: {offset, limit, only: only, ...filters},
      paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: "repeat"});
      },
    }),
  callsList: (company_id, limit, offset, only = "all", filters) =>
    getApiClient(ctx).get(`/company/${company_id}/calls/call/`, {
      params: {offset, limit, only: only, ...filters},
      paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: "repeat"});
      },
    }),
  //for initialize call
  makeCall: (company_id, phone_number) =>
    getApiClient(ctx).post(`/company/${company_id}/calls/call/`, {to: phone_number}),
  //to make call from queue as incomming
  dequeCall: (company_id, call_id) =>
    getApiClient(ctx).post(`/company/${company_id}/calls/dequeue/`, {call_id}),
  //put call on hold, provide call_id
  holdCall: (company_id, leg_sid) =>
    getApiClient(ctx).post(`/company/${company_id}/calls/hold/`, {leg_sid: leg_sid}),
  //rasume call, call_id is provided in hold resposne
  resumeCall: (company_id, call_id) =>
    getApiClient(ctx).post(`/company/${company_id}/calls/resume/`, call_id),
});
