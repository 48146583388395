import React, {useRef} from "react";

import {IconButton} from "UI";

import styles from "./NumberInput.module.css";

const NumberInput = ({
  form,
  className,
  onFocus,
  value,
  onChange,
  disabled,
  min = 0,
  max = 9999999,
  hideArrows = true,
  placeholder,
  name,
  suffix,
  error,
  defaultValue,
  onBlur,
  step = 1,
  allowDecimal = false,
}) => {
  const inputRef = useRef(form);

  const handleChange = (e) => {
    if (!onChange) return;
    if (e.target.value) {
      onChange(allowDecimal ? parseFloat(e.target.value) : parseInt(e.target.value));
    } else {
      onChange(e.target.value);
    }
  };

  const handleBlur = (e) => {
    // TODO fix
    if (onBlur) {
      onBlur();
    } else {
      if (!onChange) return;
      if (e.target.value > max) {
        onChange(max);
      } else if (e.target.value < min) {
        onChange(min);
      } else {
        onChange(allowDecimal ? parseFloat(e.target.value) : parseInt(e.target.value));
      }
    }
  };

  const handleArrow = (type) => {
    let input = inputRef?.current;
    let newValue;
    if (input) {
      if (type == "up") {
        newValue = parseFloat(input.value) + step;
      } else if (input.value > 0) {
        newValue = parseFloat(input.value) - step;
      }
      if (newValue >= min) {
        input.value = newValue;
        handleChange({target: {value: `${input.value}`}});
      }
    }
  };

  return (
    <div className={styles["input-box"]}>
      <input
        id={name}
        className={
          !error
            ? [`${styles["number-input"]} ${className}`]
            : [` ${styles["error"]} ${styles["number-input"]} ${className}`]
        }
        type="number"
        ref={form}
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        name={name}
        onFocus={onFocus}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="disabled"
        step={step || `any`}
        defaultValue={defaultValue}
        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
        // {...props}
      />
      {suffix && <div className={styles.suffix}>{suffix}</div>}
      {!hideArrows && (
        <>
          <IconButton
            className={styles.arrow}
            icon="chevron-up-sm"
            key="up"
            onClick={(e) => {
              e.preventDefault();
              handleArrow("up");
            }}
          />
          <IconButton
            className={styles.arrow}
            key="down"
            icon="chevron-down-sm"
            onClick={(e) => {
              e.preventDefault();
              handleArrow("down");
            }}
          />
        </>
      )}
    </div>
  );
};

export default NumberInput;
