import React from "react";
import cn from "classnames";

import styles from "./TextInput.module.css";

const TextInput = ({
  type,
  form,
  control,
  className,
  error,
  onFocus,
  disabled,
  readOnly,
  onChange,
  disabledInput,
  value,
  ...props
}) => {
  return (
    <input
      className={cn(styles.input, className, {
        [styles.error]: error,
        [styles.disabled]: disabled,
      })}
      type={type}
      ref={form ? form : null}
      onFocus={onFocus}
      readOnly={disabled || readOnly}
      onChange={onChange}
      control={control}
      autoComplete="disabled"
      value={value}
      {...props}
      disabled={disabledInput && !disabled ? disabledInput : disabled}
    />
  );
};

export default TextInput;
