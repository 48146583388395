import {getApiClient} from "@/api/util";

export default (ctx) => ({
  getUser: (companyId) => getApiClient(ctx).get(`/company/${companyId}/users/me/`),
  login: (email, password) =>
    getApiClient(ctx).post("/login/", {
      email,
      password,
      authentication: "session",
    }),
  logout: () => getApiClient(ctx).delete("/login/"),
});
