import React, {useEffect, useState} from "react";
import style from "./Pagination.module.css";
import cn from "classnames";
import InlineSVG from "react-inlinesvg";
import chevronRight from "../../UI/Icon/icons/chevron-right.svg";
import chevronLeft from "../../UI/Icon/icons/chevron-left.svg";

const Pagination = ({offset, count = 0, setOffset, reloadData, limit = 12, ...rest}) => {
  const [numOfPages, setNumOfPages] = useState(Math.ceil(count / limit));
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    setSelectedPage(Math.ceil(offset / limit));
  }, [offset, limit]);

  useEffect(() => {
    setNumOfPages(Math.ceil(count / limit));
  }, [count, limit]);

  const changePage = (direction) => {
    if (direction === "increase") {
      if (selectedPage + 2 <= numOfPages) {
        setSelectedPage(selectedPage + 1);
        reloadData((selectedPage + 1) * limit);
      }
    }
    if (direction === "decrease") {
      if (selectedPage - 1 > -1) {
        setSelectedPage(selectedPage - 1);
        reloadData((selectedPage - 1) * limit);
      }
    }
  };

  if (count > 0) {
    return (
      <div className={style.container} {...rest}>
        <div className={style.pageCount}>
          {selectedPage + 1} out of {numOfPages}
        </div>

        <div className={style.pagination}>
          <InlineSVG
            src={chevronLeft}
            className={style.icon}
            onClick={() => changePage("decrease")}
          />

          {[...Array(numOfPages)].map((e, i) => {
            if (
              (selectedPage === 0 && i < 4) ||
              i === selectedPage - 1 ||
              (i > selectedPage && i < selectedPage + 3) ||
              i === selectedPage ||
              (selectedPage + 1 === numOfPages && i >= selectedPage - 3) ||
              (selectedPage === numOfPages - 2 && i > selectedPage - 3)
            ) {
              return (
                <div
                  key={i}
                  onClick={(e) => {
                    setOffset((parseInt(e.target.innerText) - 1) * limit);
                    reloadData((parseInt(e.target.innerText) - 1) * limit);
                    setSelectedPage(i);
                  }}
                  className={cn([style.paginationLetter], {
                    [style.paginationUnselected]: i !== selectedPage,
                  })}
                >
                  {i + 1}
                </div>
              );
            }
          })}
          <InlineSVG
            onClick={() => changePage("increase")}
            src={chevronRight}
            className={style.icon}
          />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Pagination;
