import React from "react";

import {Icon} from "UI";

import styles from "./RoundedButton.module.css";

const RoundedButton = ({onClick, icon}) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <Icon icon={icon || "plus"} />
    </button>
  );
};

export default RoundedButton;
