import React, {useEffect} from "react";
import useVisible from "@/util/useVisible";
import cn from "classnames";

import styles from "./Popup.module.css";

const Popup = ({id, className, classNamePopup, onClick, isActive, list, empty}) => {
  const {ref: popupRef, isVisible: isPopupVisible, setIsVisible: setIsPopupVisible} = useVisible(
    true
  );

  useEffect(() => {
    isActive == -1 ? setIsPopupVisible(false) : setIsPopupVisible(true);
  }, [isActive]);

  return (
    <>
      {isPopupVisible && isActive == id && (
        <div ref={popupRef} className={cn(styles["container"], className)} onClick={onClick}>
          {list && list.length > 1 ? (
            <ul className={cn(styles["container__list"], classNamePopup)}>
              {list.map(
                ({id, value}, i) =>
                  i > 0 && (
                    <li key={id} className={styles["list__item"]}>
                      {value}
                    </li>
                  )
              )}
            </ul>
          ) : (
            <ul className={cn(styles["container__list"], classNamePopup)}>
              <li key={id} className={styles["list__item"]}>
                {empty}
              </li>
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default Popup;
