import React from "react";
import cn from "classnames";

import {Icon} from "UI";

import styles from "./CreateNewButton.module.css";

const CreateNewButton = ({
  children,
  onClick,
  className,
  icon,
  color,
  backgroundColor,
  bgColorIcon,
  marginLeft,
  marginRight,
  isSave = false,
}) => {
  return (
    <button
      className={cn(styles["add-new-button"], className)}
      onClick={onClick}
      style={{backgroundColor, color, marginLeft, marginRight}}
    >
      <div className={isSave ? styles["save-button"] : styles["add-new-button-content"]}>
        {children}
        {!isSave && (
          <div className={styles["plus-container"]} style={{backgroundColor: bgColorIcon}}>
            <Icon icon={icon || "plus"} color={color || "white"} className={styles.plusmark} />
          </div>
        )}
      </div>
    </button>
  );
};

export default CreateNewButton;
