import React from "react";
import cn from "classnames";

import styles from "./RadioInput.module.css";

const RadioInput = (props) => {
  const {form, className, name, id, value, checked, onChange} = props;

  const handleChange = (e) => {
    onChange(e.currentTarget.value);
  };

  return (
    <input
      className={cn(styles.radio, checked ? styles.activeRadio : "", className)}
      type={`radio`}
      ref={form}
      name={name}
      id={id}
      value={value}
      checked={checked}
      onChange={handleChange}
    />
  );
};

export default RadioInput;
