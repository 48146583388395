import callApi from "@/api/calls/calls";
import {Device} from "@twilio/voice-sdk";
import jwtDecode from "jwt-decode";
import moment from "moment";
import {getSafeDeep} from "@/util/helperFunctions";
import * as WebSocketClient from "./WebSocketClient";
import {idempotentFunction} from "@/util/helper";
import {DANGER, notify} from "UI";

export const CALLS_STATE_NOT_CONNECTED = 0;
export const CALLS_STATE_CONNECTING = 1;
export const CALLS_STATE_CONNECTED = 2;

export const CALL_STATUS_INCOMING = 0;
export const CALL_STATUS_OUTGOING = 1;
export const CALL_STATUS_CONNECTED = 2;
export const CALL_STATUS_HOLD = 3;
export const CALL_STATUS_DISCONNECTED = 4;

const stateListeners = [];

let state = {
  activeCalls: [],
  queuedCalls: [],
  currentCall: null,
  error: null,
  companies: [],
};

const onCallQueued = (wsData) => {
  state.queuedCalls.push({
    state: CALL_STATUS_INCOMING,
    data: {
      company_id: wsData.data.company_id,
      company_name: wsData.data.company_name,
      call_id: wsData.data.call_id,
      caller: wsData.data.caller,
    },
    twilioConnection: null,
    startTime: moment(),
  });
  notifyListeners();
};

const onCallDequeued = (wsData) => {
  let targetIndex = state.queuedCalls.findIndex((each) => each.data.call_id == wsData.data.call_id);

  if (targetIndex > -1) {
    state.queuedCalls.splice(targetIndex, 1);
  }
  notifyListeners();
};

const onAbortedCallHold = (wsData) => {
  let targetIndex = state.activeCalls.findIndex((each) => each.data.call_id == wsData.data.call_id);
  if (targetIndex > -1) {
    state.activeCalls.splice(targetIndex, 1);
  }
  notifyListeners();
};

const onCanHoldCall = (wsData) => {
  if (state.currentCall.data.call_id == wsData.data.call_id) {
    state.currentCall.data.canHold = true;
  }
  notifyListeners();
};

const onCanResumeCall = (wsData) => {
  let targetIndex = state.activeCalls.findIndex((each) => each.data.call_id == wsData.data.call_id);
  if (targetIndex > -1) {
    state.activeCalls[targetIndex].data.canResume = true;
  }
  notifyListeners();
};

// let focused = true;
if (typeof window != "undefined") {
  // window.addEventListener("focus", () => {
  //   focused = true;
  // });
  // window.addEventListener("blur", () => {
  //   focused = false;
  // });
  WebSocketClient.addMessageListener("call_queued", onCallQueued);
  WebSocketClient.addMessageListener("call_dequeued", onCallDequeued);
  WebSocketClient.addMessageListener("aborted_call_queue", onCallDequeued);
  WebSocketClient.addMessageListener("aborted_call_hold", onAbortedCallHold);
  WebSocketClient.addMessageListener("can_hold_call", onCanHoldCall);
  WebSocketClient.addMessageListener("can_resume_call", onCanResumeCall);
}

const notifyListeners = () => stateListeners.forEach((listener) => listener(state));

const loadCalls = async () => {
  const [{data: queued}, {data: hold}] = await Promise.all([
    callApi().currentCalls(10, 0, "all", {
      status: 2,
    }),
    callApi().currentCalls(10, 0, "all", {
      status: 1,
      own: true,
    }),
  ]);
  queued.results
    .filter((newCall) => !state.queuedCalls.some((call) => call.data.call_id === newCall.id))
    .forEach((newCall) =>
      state.queuedCalls.push({
        state: CALL_STATUS_INCOMING,
        data: {
          company_id: newCall.company_id,
          lead_id: newCall.lead_id,
          call_id: newCall.id,
          caller: newCall.caller,
        },
        twilioConnection: null,
        startTime: moment(),
      })
    );
  hold.results
    .filter((newCall) => !state.activeCalls.some((call) => call.data.call_id === newCall.id))
    .forEach((newCall) =>
      state.activeCalls.push({
        state: CALL_STATUS_HOLD,
        data: {
          company_id: newCall.company_id,
          lead_id: newCall.lead_id,
          call_id: newCall.id,
          caller: newCall.caller,
        },
        twilioConnection: null,
        startTime: moment(newCall.timestamp),
      })
    );
};

const refreshDeviceToken = async (companyId) => {
  try {
    const {data} = await callApi().getToken(companyId);
    const decodedToken = jwtDecode(data.token);
    let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
    if (companyIndex) {
      state.companies[companyIndex]?.twilioDevice.updateToken(data.token);
      state.companies[companyIndex].identity = decodedToken.grants.identity;
    }
    notifyListeners();
  } catch (e) {
    console.error(e);
    state.error = e;
    notifyListeners();
  }
};

const onConnectionAccept = (conn) => {
  console.log("%c onConnectionAccept conn", "color: red; font-size: 16px;", conn);
};

const onConnectionReject = (conn) => {
  console.log("%c onConnectionReject conn", "color: red; font-size: 16px;", conn);
};

const onMessageReceived = (msg) => {
  console.log("%c onMessageReceived msg", "color: red; font-size: 16px;", msg);
  if (msg?.content?.event === "client_answered") {
    state.currentCall.state = CALL_STATUS_CONNECTED;
    state.currentCall.data.canHold = true;
    notifyListeners();
  }
};

const onIncomingConnection = (connection, companyCurrentId) => {
  console.log("%c connection", "color: blue; font-size: 16px;", connection);
  connection.on("cancel", () => onConnectionCancel(connection));
  connection.on("disconnect", onConnectionDisconnect);
  connection.on("accept", onConnectionAccept);
  connection.on("reject", onConnectionReject);
  connection.on("messageReceived", onMessageReceived);

  //if calling you || if you're calling someone else
  const companyId = connection?.customParameters?.get("company_id") || companyCurrentId;
  const companyName = connection?.customParameters?.get("company_name");
  let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
  // if there is third call or we are not connected
  if (
    state.activeCalls.length > 2 ||
    state.companies?.[companyIndex]?.connected === CALLS_STATE_NOT_CONNECTED
  ) {
    console.log("%c usao u reject", "color: red; font-size: 16px;");
    connection.reject();
    return;
  }

  const call_id = connection?.customParameters?.get("call_id");
  let call = state.activeCalls.find((call) => getSafeDeep(call, "data.call_id") == call_id);

  if (call) {
    state.currentCall = call;
    call.data = {
      company_id: companyId,
      company_name: companyName,
      call_id: call_id,
    };
    call.twilioConnection = connection;
    call.state = CALL_STATUS_CONNECTED;
    call.twilioConnection.accept();
    notifyListeners();
    return;
  }

  const from = connection.parameters.From;
  call = state.activeCalls.find(
    (call) => getSafeDeep(call, "twilioConnection.parameters.From") == from
  );

  if (!call) {
    call = {
      state: CALL_STATUS_INCOMING,
      data: {
        company_id: companyId,
        company_name: companyName,
        call_id: call_id,
      },
      twilioConnection: connection,
      startTime: moment(),
    };
    state.activeCalls.push(call);
  }

  const autoAnswer = connection?.customParameters?.get("autoAnswer");
  if (autoAnswer || from === `client:${state.companies?.[companyIndex]?.identity}`) {
    setCurrentCall(call);
  }

  notifyListeners();
};

const onConnectionCancel = (conn) => {
  console.log("%c connection on cancel", "color: magenta; font-size: 16px;", conn);
  const connIndex = state.activeCalls.findIndex((call) => call.twilioConnection === conn);
  if (connIndex !== -1) {
    if (state.activeCalls[connIndex] === state.currentCall) {
      state.currentCall = null;
    }
    state.activeCalls.splice(connIndex, 1);
  }
  notifyListeners();
};

const onConnectionDisconnect = (conn) => {
  console.log("%c connection on disconnect", "color: magenta; font-size: 16px;", conn);
  const connIndex = state.activeCalls.findIndex((call) => call.twilioConnection === conn);
  if (connIndex !== -1) {
    if (state.activeCalls[connIndex] === state.currentCall) {
      state.currentCall = null;
    }
    state.activeCalls.splice(connIndex, 1);
  }
  notifyListeners();
};

const onTwilioReady = (companyId) => {
  let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
  if (state.companies?.[companyIndex]) {
    state.companies[companyIndex].connected = CALLS_STATE_CONNECTED;
  }
  state.error = null;
  notifyListeners();
};

const onTwilioError = (err, companyId) => {
  console.log("%c err", "color: magenta; font-size: 16px;", err);
  let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
  if (state.companies?.[companyIndex]) {
    state.companies[companyIndex].connected = CALLS_STATE_CONNECTING;
  }
  state.error = err;
  notifyListeners();
};

const tryConnect = async (companyId) => {
  let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
  try {
    const {data} = await callApi().getToken(companyId);
    const decodedToken = jwtDecode(data.token);
    notifyListeners();
    const device = new Device(data.token, {
      allowIncomingWhileBusy: true,
      closeProtection: true,
      // logLevel: "debug",
    });
    if (companyIndex >= 0) {
      state.companies[companyIndex].connected = CALLS_STATE_CONNECTING;
      state.companies[companyIndex].twilioDevice = device;
      state.companies[companyIndex].identity = decodedToken.grants.identity;
    } else {
      state.companies.push({
        companyId: companyId,
        connected: CALLS_STATE_CONNECTING,
        twilioDevice: device,
        identity: decodedToken.grants.identity,
      });
    }
    device.register();
    device.on("tokenWillExpire", () => refreshDeviceToken(companyId));
    device.on("registered", () => onTwilioReady(companyId));
    device.on("incoming", (conn) => onIncomingConnection(conn, companyId));
    device.on("error", (err) => onTwilioError(err, companyId));
    notifyListeners();
  } catch (e) {
    console.error(e);
    state.error = e;
    if (state.companies?.[companyIndex]) {
      state.companies[companyIndex].connected = CALLS_STATE_NOT_CONNECTED;
    }
  }
};

export const reset = () => {
  state.companies.forEach((company) => company.twilioDevice?.destroy());
  state = {
    activeCalls: [],
    queuedCalls: [],
    currentCall: null,
    error: null,
    companies: [],
  };
  notifyListeners();
};

/**
 * Initialize webhook for calls and twilio devices
 * This function is safe to be called multiple times
 */
export const initialize = idempotentFunction("initialize", async (companyIds) => {
  // Mladjone maybe not necessary
  if (process.env.NODE_ENV === "development") {
    window.callsState = state;
  }
  for (const companyId of companyIds) {
    let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
    if (
      state.companies?.[companyIndex]?.connected === CALLS_STATE_CONNECTED ||
      state.companies?.[companyIndex]?.connected === CALLS_STATE_CONNECTING
    ) {
      continue;
    } else {
      await tryConnect(companyId);
    }
  }
  await loadCalls();
  notifyListeners();
});

export const setCallOnHold = idempotentFunction("setCallOnHold", async (call) => {
  console.log("%c setCallOnHold - call", "color: blue; font-size: 16px;", call);
  let companyId = call.data.company_id;
  let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
  if (state.companies?.[companyIndex]?.connected !== CALLS_STATE_CONNECTED) {
    throw new Error("Calls not initialized");
  }
  call.state = CALL_STATUS_HOLD;
  const callSid = call.twilioConnection.parameters.CallSid;
  try {
    const hold = await callApi().holdCall(companyId, callSid);
    call.data.lead_id = hold.data.lead_id;
    call.data.company_id = hold.data.company_id;
    call.data.call_id = hold.data.call_id;
    // call.twilioConnection = null; //Mladjone hold functionality changed on backend
    if (state.currentCall === call) {
      state.currentCall = null;
    }
    notifyListeners();
  } catch (error) {
    console.log("Problem in setCallOnHold can't hold call.", error);
  }
});

export const setCurrentCall = idempotentFunction("setCurrentCall", async (call) => {
  console.log("%c setCurrentCall - call", "color: red; font-size: 16px;", call);
  let companyId = call.data.company_id;
  let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
  if (state.companies?.[companyIndex]?.connected !== CALLS_STATE_CONNECTED) {
    throw new Error("Calls not initialized");
  }
  if (state.currentCall != null && state.currentCall !== call) {
    // let currentActiveCall = state.activeCalls?.find(item => item.twilioConnection?.outboundConnectionId === state.currentCall?.twilioConnection?.outboundConnectionId)
    state.currentCall.twilioConnection.removeListener("disconnect", onConnectionDisconnect);
    await setCallOnHold(state.currentCall);
    state.currentCall?.twilioConnection?.on("disconnect", onConnectionDisconnect);
  }
  if (call.state === CALL_STATUS_HOLD) {
    try {
      await callApi().resumeCall(companyId, call.data);
      state.currentCall = call;
      call.state = CALL_STATUS_CONNECTED;
      notifyListeners();
    } catch (error) {
      console.log("Problem in setCurrentCall can't resume call.", error);
    }
  } else {
    const from = call.twilioConnection.parameters.From;
    if (from === `client:${state.companies?.[companyIndex]?.identity}`) {
      call.state = CALL_STATUS_OUTGOING;
    } else {
      call.state = CALL_STATUS_CONNECTED;
    }
    call.twilioConnection.accept();
    state.currentCall = call;
    notifyListeners();
  }
});
export const rejectCall = (call) => {
  let companyId = call.data.company_id;
  let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
  if (state.companies?.[companyIndex]?.connected !== CALLS_STATE_CONNECTED) {
    throw new Error("Calls not initialized");
  }
  if (call.twilioConnection != null) {
    call.twilioConnection.reject();
  }
  const callIndex = state.activeCalls.indexOf(call);
  if (callIndex !== -1) {
    state.activeCalls.splice(callIndex, 1);
  }
  notifyListeners();
};

export const finishCall = (call) => {
  let companyId = call.data.company_id;
  let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
  if (state.companies?.[companyIndex]?.connected !== CALLS_STATE_CONNECTED) {
    throw new Error("Calls not initialized");
  }
  if (state.currentCall === call) {
    state.currentCall = null;
  }
  console.log("%c finishCall - call", "color: red; font-size: 16px;", call);
  call.twilioConnection.disconnect();
  const callIndex = state.activeCalls.indexOf(call);
  if (callIndex !== -1) {
    state.activeCalls.splice(callIndex, 1);
  }
  notifyListeners();
};

export const callNumber = idempotentFunction("callNumber", async (companyId, number) => {
  let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
  if (state.companies?.[companyIndex]?.connected !== CALLS_STATE_CONNECTED) {
    throw new Error("Calls not initialized");
  }
  try {
    await callApi().makeCall(companyId, number);
  } catch (error) {
    notify(DANGER, error.response?.data?.reason || "Invalid phone number");
  }
});

export const acceptQueuedCall = idempotentFunction("acceptQueuedCall", async (call) => {
  let companyId = call.data.company_id;
  let companyIndex = state.companies.findIndex((company) => company.companyId == companyId);
  if (state.companies?.[companyIndex]?.connected !== CALLS_STATE_CONNECTED) {
    throw new Error("Calls not initialized");
  }
  try {
    await callApi().dequeCall(companyId, call.data.call_id);
  } catch (error) {
    console.log("Problem in acceptQueuedCall can't deque call.", error);
  }
});

export const subscribeToState = (listener) => {
  return stateListeners.push(listener);
};
export const unsubscribeToState = (listener) => {
  const index = stateListeners.indexOf(listener);
  stateListeners.splice(index, 1);
};

export const getState = () => state;
