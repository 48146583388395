import React from "react";
import {toast} from "react-toastify";
import Notification from "../Notifications";
import styles from "./Notify.module.css";

const notify = (type, message, description, err) => {
  return toast(<Notification type={type} message={message} description={description} />, {
    // autoClose: 7000,
    className: styles["toast-background"],
    bodyClassName: styles["toast-body"],
    progressClassName: styles["toast-progress"],
    // closeOnClick: true,
    toastId: `${type}-${message}-${description}`,
    draggable: false,
  });
};

export default notify;
