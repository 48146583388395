import React, {useEffect} from "react";
import {handleErrors} from "@/util/helperFunctions";
import PlaceAutocompleteInput from "UI/PlaceAutocompleteInput/PlaceAutocompleteInput";
import {DANGER} from "UI";
import styles from "./FormPlacesInput.module.css";

const FormPlacesInput = (props) => {
  const {
    error,
    label,
    placeholder,
    name,
    onFocus,
    form,
    validation,
    type,
    value,
    className,
    onChange,
    hide,
    onPlaceSelect,
    PostalCodeSearch,
    renderError = false,
  } = props;

  useEffect(() => {
    console.log(error);
    error && error.type == "validate" ? handleErrors(error, error.message, DANGER) : "";
  }, [error]);

  return (
    <div className={styles["input-box"]} style={hide ? {display: "none", width: 0} : {}}>
      <label className={styles.label}>
        {label}
        {validation && validation.required && <span className="text-danger">*</span>}
      </label>
      <PlaceAutocompleteInput
        error={error}
        form={form ? form(validation) : null}
        placeholder={placeholder}
        name={name}
        value={value}
        className={!error ? className : [`${className}, ${styles["danger"]}`]}
        onChange={onChange}
        onPlaceSelect={onPlaceSelect}
        onFocus={onFocus}
        PostalCodeSearch={PostalCodeSearch}
      />
      {renderError && error && (
        <small style={{color: "#f40034", marginTop: "3px"}}>
          {console.log(error)}
          {typeof error == "object" ? error.message : error}
        </small>
      )}
    </div>
  );
};

export default FormPlacesInput;
