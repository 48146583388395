import React, {Fragment} from "react";
import styles from "./Range.module.css";

import cn from "classnames";

import {Slider, Rail, Handles, Tracks} from "react-compound-slider";

const Range = ({
  label,
  min = 0,
  max = 10,
  value = [2, 7],
  className,
  onChange,
  forRevenue = false,
}) => {
  const onUpdate = (e) => {
    // console.log(e)
  };
  return (
    <div className={cn(styles["range-container"], className)}>
      {forRevenue ? (
        <div className={styles["label-container"]}>
          {value && <span className={styles["current-position"]}>{"$" + value[1]}</span>}
          <span className={styles["target"]}>{"$" + max}</span>
        </div>
      ) : (
        <div className={styles["label-container"]}>
          <span>{label}</span>
          {value && (
            <span>
              {value[0]} - {value[1]}
            </span>
          )}
        </div>
      )}
      <Slider
        mode={2}
        step={1}
        domain={[min, max]}
        // rootStyle={sliderStyle}
        onUpdate={onUpdate}
        onChange={onChange}
        values={value}
        disabled={forRevenue}
      >
        <Rail>
          {({getRailProps}) => <SliderRail getRailProps={getRailProps} forRevenue={forRevenue} />}
        </Rail>
        {!forRevenue && (
          <Handles>
            {({handles, getHandleProps}) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={[min, max]}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
        )}
        <Tracks left={false} right={false}>
          {({tracks, getTrackProps}) => (
            <div className="slider-tracks">
              {tracks.map(({id, source, target}) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
      {forRevenue ? (
        <div className={styles["value-container"]}>
          <span className={styles["small-text"]}>Current Position</span>
          <span className={styles["small-text"]}>Target</span>
        </div>
      ) : (
        <div className={styles["value-container"]}>
          <span>{min}</span>
          <span>{max}</span>
        </div>
      )}
    </div>
  );
};

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
  position: "absolute",
  width: "100%",
  height: 4,
  transform: "translate(0%, -50%)",
  borderRadius: 2,
  cursor: "pointer",
  // border: '1px solid white',
};

const railInnerStyle = {
  position: "absolute",
  width: "100%",
  height: 4,
  transform: "translate(0%, -50%)",
  borderRadius: 2,
  pointerEvents: "none",
};

export function SliderRail({getRailProps, forRevenue}) {
  return (
    <Fragment>
      <div style={railOuterStyle} {...getRailProps()} />
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: 4,
          transform: "translate(0%, -50%)",
          borderRadius: 2,
          pointerEvents: "none",
          backgroundColor: forRevenue ? "#ddd" : "#f5f6fa",
        }}
      />
    </Fragment>
  );
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
  domain: [min, max],
  handle: {id, value, percent},
  disabled,
  getHandleProps,
}) {
  return (
    <Fragment>
      <div
        //clickable
        style={{
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
          zIndex: 5,
          width: 16,
          height: 16,
          cursor: "pointer",
          // border: '1px solid white',
          backgroundColor: "none",
        }}
        {...getHandleProps(id)}
      />
      <div
        //preview
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          width: 16,
          height: 16,
          borderRadius: "50%",
          // boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
          backgroundColor: disabled ? "#666" : "#596bff",
        }}
      />
    </Fragment>
  );
}

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export function KeyboardHandle({
  domain: [min, max],
  handle: {id, value, percent},
  disabled,
  getHandleProps,
}) {
  return (
    <button
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
        position: "absolute",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        width: 16,
        height: 16,
        borderRadius: "50%",
        boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0)",
        backgroundColor: disabled ? "#666" : "#596bff",
      }}
      {...getHandleProps(id)}
    />
  );
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({source, target, getTrackProps, disabled}) {
  return (
    <div
      style={{
        position: "absolute",
        transform: "translate(0%, -50%)",
        height: 4,
        zIndex: 1,
        backgroundColor: disabled ? "#999" : "#596bff",
        borderRadius: 2,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

export default Range;
