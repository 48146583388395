import React from "react";
import cn from "classnames";

import styles from "./Pill.module.css";

export default function Pill({text, active, onClick}) {
  return (
    <div className={cn(styles["pill-container"], active ? styles["active"] : "")} onClick={onClick}>
      {text}
    </div>
  );
}
