import React from "react";

import classes from "./Loader.module.css";

const Loader = ({isLoading}) => {
  return (
    isLoading && (
      <div className={classes["loader"]}>
        <div className={classes["lds-ring"]}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  );
};

export default Loader;
