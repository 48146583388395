import {getApiClient} from "@/api/util";

export default (ctx) => ({
  //get offices
  getOffices: (company_id) => getApiClient(ctx).get(`company/${company_id}/office/`),
  //add or invite user
  addUser: (company_id, data) =>
    getApiClient(ctx).post(`/company/${company_id}/users/invite/`, data),
  //complete profile public page
  completeProfile: (key) => getApiClient(ctx).post(`/users/activate/${key}/`),
  //edit as user
  userGetData: (company_id) => getApiClient(ctx).get(`company/${company_id}/users/me/`),
  userEditData: (company_id, data) =>
    getApiClient(ctx).put(`company/${company_id}/users/me/`, data),
  //list of users :
  usersList: (company_id, params) =>
    getApiClient(ctx).get(`/company/${company_id}/users/`, {params}),
  //change password
  changePassword: (data) => getApiClient(ctx).put(`/password/change/`, data),
  resendEmail: (data) => getApiClient(ctx).post(`/email/resend/`, data),
  //edit as EditAsAdmin
  adminGetData: (company_id, user_id) =>
    getApiClient(ctx).get(`company/${company_id}/users/${user_id}/`),
  adminEditData: (company_id, user_id, data) =>
    getApiClient(ctx).put(`company/${company_id}/users/${user_id}/`, data),
  inviteUser: (company_id, data) =>
    getApiClient(ctx).post(`/company/${company_id}/users/invite/`, data),
  activateUser: (key, data) => getApiClient(ctx).post(`/users/activate/${key}/`, data),
  activateCompanyUser: (user_id) => getApiClient(ctx).put(`/users/activate-user/${user_id}/`),
  activateExistingUser: (key) => getApiClient(ctx).post(`/users/join/${key}/`),
  getPendingUser: (key) => getApiClient(ctx).get(`/users/activate/${key}/`),
  getUserById: (company_id, user_id) =>
    getApiClient(ctx).get(`/company/${company_id}/users/${user_id}/`),
  deleteUserById: (company_id, user_id, data) =>
    getApiClient(ctx).delete(`/company/${company_id}/users/${user_id}/`, {
      params: {
        user: data,
      },
    }),
  changePhoto: (company_id, user_id) =>
    getApiClient(ctx).post(`/company/${company_id}/users/${user_id}/photo/change/`),
  partialUpdate: (company_id, user_id, data) =>
    getApiClient(ctx).patch(`/company/${company_id}/users/${user_id}/`, data),
});
