import React, {useState} from "react";

import styles from "./MonthPicker.module.css";
import cn from "classnames";

import {Icon} from "UI";

const MonthPicker = (props) => {
  const {selectedYear, setSelectedYear, setSelectedMonth, selectedMonth, showPicker} = props;
  const months = [
    {label: "Jan", value: 0},
    {label: "Feb", value: 1},
    {label: "Mar", value: 2},
    {label: "Apr", value: 3},
    {label: "May", value: 4},
    {label: "Jun", value: 5},
    {label: "Jul", value: 6},
    {label: "Aug", value: 7},
    {label: "Sep", value: 8},
    {label: "Oct", value: 9},
    {label: "Nov", value: 10},
    {label: "Dec", value: 11},
  ];
  const [activeMonth, setActiveMonth] = useState(new Date().getMonth());

  if (!showPicker) return null;
  return (
    <div className={styles["container"]}>
      <div className={styles["header"]}>
        <Icon
          icon="chevron-left-small"
          className={styles["chevron-left"]}
          onClick={() => setSelectedYear(selectedYear - 1)}
        />
        <span>{selectedYear}</span>
        <Icon
          icon="chevron-right-small"
          className={styles["chevron-right"]}
          onClick={() => setSelectedYear(selectedYear + 1)}
        />
      </div>
      <div className={styles["months-container"]}>
        {months.map((month) => (
          <div
            key={month.value}
            className={cn(
              styles["month"]
              // activeMonth == month.value ? styles.active : null
            )}
            onClick={() => {
              setSelectedMonth(month.value);
              setActiveMonth(month.value);
            }}
          >
            {month.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthPicker;
