import {getApiClient} from "@/api/util";

export default (ctx) => ({
  getAllPlans: (limit = 3, offset = 0) =>
    getApiClient(ctx).get(`/plans/`, {
      params: {
        limit,
        offset,
      },
    }),
  startTrial: (company_id) => getApiClient(ctx).post(`/company/${company_id}/start-trial/`),
  createPaymentIntent: (company_id, currency, amount) =>
    getApiClient(ctx).post(`/company/${company_id}/create-payment-intent/`, {
      payment_method_types: ["card"],
      currency,
      amount,
    }),
  createSubscription: (company_id, payment_method_id, customer_id, plan_id, is_yearly) =>
    getApiClient(ctx).post(`/company/${company_id}/create-subscription/`, {
      payment_method_id,
      customer_id,
      plan_id,
      is_yearly,
    }),
  upgradePlan: (company_id, plan_id, customer_id) =>
    getApiClient(ctx).post(`/company/${company_id}/update-subscription/`, {
      plan_id,
      customer_id,
    }),
  cancelSubscription: (company_id, cancel_at_period_end) =>
    getApiClient(ctx).post(`/company/${company_id}/cancel-subscription/`, {
      cancel_at_period_end,
    }),
  getInvoices: (company_id, office_id, params) =>
    getApiClient(ctx).get(`/company/${company_id}/payments/${office_id}/`, {params}),
  getStats: (company_id, office_id, params) =>
    getApiClient(ctx).get(`/company/${company_id}/payments/${office_id}/stats/`, {params}),
  editManualAdjustment: (company_id, quote_id, data) =>
    getApiClient(ctx).put(`/company/${company_id}/payments/${quote_id}/manual-adjustment/`, data),
  getHostedPage: (company_id, params) =>
    getApiClient(ctx).get(`/payments/${company_id}/hosted_page/`, {params}),
});
