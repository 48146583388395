import React, {useState, useEffect} from "react";
import {useRouter} from "next/router";
import {withRedux} from "@/redux/reduxUtils";
import {useSelector} from "react-redux";
import twilioAPI from "@/api/twilio/twilio";
import BuyNumber from "./Dialogs/BuyNumber";
import Connect from "./Dialogs/Connect";
import Disconnect from "./Dialogs/Disconnect";

const createStore = (initialStore) => {
  let store = initialStore;
  const listeners = new Set();

  const setStore = (newStore) => {
    // Make it like reacts setState so if you pass in a function you can get the store value first
    store = typeof newStore === "function" ? newStore(store) : newStore;
    listeners.forEach((listener) => listener(() => store));
  };

  const useStore = () => {
    const [, listener] = useState();
    useEffect(() => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    }, []);
    return store;
  };

  return {useStore, setStore};
};

const initialStore = null;
export const {useStore, setStore} = createStore(initialStore);

const TwilioContainer = () => {
  const company = useSelector((state) => state.company);
  const isOpen = useStore();
  const router = useRouter();
  useEffect(() => {
    setStore(initialStore);
  }, [router]);

  const onSuccessfulConnection = async () => {
    try {
      const res = await twilioAPI().getPhoneNumbers(company?.current?.id);
      if (res.data?.count > 0) {
        setStore(initialStore);
      } else {
        setStore("number");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Connect
        isOpen={isOpen === "connect"}
        closeDialog={() => setStore(initialStore)}
        onSuccess={onSuccessfulConnection}
      />
      <Disconnect
        isOpen={isOpen === "disconnect"}
        closeDialog={() => setStore(initialStore)}
        onSuccess={() => setStore(initialStore)}
      />
      {isOpen === "number" && (
        <BuyNumber
          isOpen={isOpen === "number"}
          closeDialog={() => setStore(initialStore)}
          onSuccess={() => setStore(initialStore)}
        />
      )}
    </>
  );
};

export default withRedux(TwilioContainer);
