import {getApiClient} from "@/api/util";

export default (ctx) => ({
  membership: (offset, limit) => getApiClient(ctx).get(`/membership/`, {params: {offset, limit}}),
  getCompanyInfo: (company_id) => getApiClient(ctx).get(`/company/${company_id}/`),
  getCompanyInfoByHash: (hash) => getApiClient(ctx).get(`/company/${hash}/`),
  create: (data) => getApiClient(ctx).post("/company/", data),
  edit: (company_id, data) => getApiClient(ctx).put(`/company/${company_id}/`, data),
  patch: (company_id, data) => getApiClient(ctx).patch(`/company/${company_id}/`, data),
  access: {
    assign: (company_id, user_id, role_id) =>
      getApiClient(ctx).post(`/company/${company_id}/access/assign/`, {
        user_id,
        role_id,
      }),
    permissions: (company_id, offset = 0, limit = 10) =>
      getApiClient(ctx).get(`/company/${company_id}/access/permissions/`, {
        params: {offset, limit},
      }),
  },
  users: {
    create: (company_id, user) => getApiClient(ctx).post(`/company/${company_id}/users/`, user),
    list: (company_id, offset = 0, limit = 10, search) =>
      getApiClient(ctx).get(`/company/${company_id}/users/`, {
        params: {offset, limit, search},
      }),
    user: (company_id, user_id) =>
      getApiClient(ctx).get(`/company/${company_id}/users/${user_id}/`),
  },
  cheat_sheet: {
    getSheet: (company_id) => getApiClient(ctx).get(`/company/${company_id}/cheat_sheet/`),
    editSheet: (company_id, data) =>
      getApiClient(ctx).put(`/company/${company_id}/cheat_sheet/`, data),
  },
  getPresignedUrl: (company_id, {file_name, folder}) =>
    getApiClient(ctx).post(`/company/${company_id}/presign-post/`, {
      file_name,
      folder,
    }),
});
