import React from "react";
import cn from "classnames";

import {RadioInput} from "UI";

import styles from "./FormRadioInput.module.css";

const FormRadioInput = (props) => {
  const {
    className,
    form,
    error,
    label,
    removeLabelElement,
    name,
    options,
    validation,
    align,
    onChange,
    value,
  } = props;

  return (
    <div className={styles.container}>
      {!removeLabelElement && (
        <label className={styles.label}>
          {label}
          {validation && validation.required && <span className="text-danger">*</span>}
        </label>
      )}
      <div className={cn(styles.radios, className)}>
        {options.map((opt, i) => (
          <div key={i} className={cn(styles.radio, styles[align])}>
            <RadioInput
              form={form ? form(validation) : null}
              name={name}
              id={opt.label}
              value={opt.value}
              checked={opt.value === value}
              onChange={onChange}
            />
            <label htmlFor={opt.label} className={styles["radio__label"]}>
              {opt.label}
            </label>
            <br></br>
          </div>
        ))}
      </div>
      {error && (
        <small style={{color: "red", marginTop: "3px"}}>
          {typeof error == "object" ? JSON.stringify(error) : error}
        </small>
      )}
    </div>
  );
};

export default FormRadioInput;
