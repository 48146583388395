import React, {useState, useEffect} from "react";
import {withRedux} from "@/redux/reduxUtils";
import {useSelector} from "react-redux";
import {getData} from "country-list";
import numberApi from "@/api/number/number";
import officeApi from "@/api/office/office";
import {PermissionGuard} from "@/util/authUtils";
import {ModalDialog, Icon, SelectInput, TextInput, notify, SUCCESS, DANGER} from "UI";
import styles from "./Dialogs.module.css";

const BuyNumber = ({isOpen, closeDialog, onSuccess}) => {
  const companyId = useSelector((state) => state.company?.current?.id);
  const [availableNumbers, setAvailableNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [shouldShow, setShouldShow] = useState(false);

  const [country, setCountry] = useState(null);
  const [area, setArea] = useState("");
  const [state, setState] = useState("");

  const searchForNumbers = async () => {
    try {
      setShouldShow(true);
      setIsLoading(true);
      const res = await numberApi().getAvailableNumbers(
        companyId,
        area && area,
        country && country.value,
        state && state
      );

      setAvailableNumbers(res.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notify(DANGER, "Problem with finding new numbers.");
      console.log(e);
    }
  };

  const [office, setOffice] = useState([]);
  const [offices, setOffices] = useState([]);
  const getOffices = async (companyId) => {
    try {
      const res = await officeApi().getOffices(companyId);
      let optionsList = res.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setOffices(optionsList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOffices(companyId);
  }, [companyId]);

  return (
    <ModalDialog isOpen={isOpen} toggle={closeDialog} title="Get New Number">
      <div className={styles["content"]}>
        <PermissionGuard permission={"settings_twilio_phones_modify"} permissionValue={[0, 1, 2]}>
          <GetNewNumber
            country={country}
            setCountry={setCountry}
            offices={offices}
            office={office}
            setOffice={setOffice}
            area={area}
            setArea={setArea}
            state={state}
            setState={setState}
            searchForNumbers={() => searchForNumbers()}
            isLoading={isLoading}
          />
          {/*Available numbers */}
          {availableNumbers.length ? (
            <AvailableNumbers
              companyId={companyId}
              numbers={availableNumbers}
              office={office}
              onSuccess={onSuccess}
            />
          ) : (
            shouldShow &&
            !isLoading && <span>There are no numbers available for these conditions</span>
          )}
        </PermissionGuard>
      </div>
    </ModalDialog>
  );
};

const GetNewNumber = (props) => {
  const {
    country,
    setCountry,
    offices,
    office,
    setOffice,
    area,
    setArea,
    state,
    setState,
    searchForNumbers,
    isLoading,
  } = props;

  return (
    <div className={styles["get-new-number-container"]}>
      <span>
        Search, purchase and assign new twilio numbers to groups below. Note: US and Canada twilio
        numbers have the most features (ie MMS and to/fom support to all countires). Some groups may
        desire a number local to their country; in this case we might recommend purchasing numbers
        from US as well as one per desired country. The Lead Manager interface should advise when
        SMS cannot be sent (ie FR cannot SMS to a US number). Read more here about twilo number use
        restrictions to best inform purchasing decisions.
      </span>
      <div className={styles["input-fields-container"]}>
        <label>Country</label>
        <SelectInput
          name="country_code"
          format="text"
          value={country}
          options={getData().map((geo) => ({
            value: geo.code,
            label: geo.name,
          }))}
          setValue={setCountry}
          placeholder="Select country"
        />
      </div>
      <div className={styles["input-fields-container"]}>
        <label>Locality</label>
        <TextInput
          type="text"
          name="state"
          value={state}
          onChange={(e) => setState(e.target.value)}
          placeholder="Enter locality"
        />
      </div>
      <div className={styles["input-fields-container"]}>
        <label>Area code </label>
        <TextInput
          type="number"
          name="area_code"
          format="text"
          value={area}
          onChange={(e) => setArea(e.target.value)}
          placeholder="Enter area code "
        />
      </div>
      <div className={styles["input-fields-container"]}>
        <label>Office</label>
        <SelectInput
          name="office"
          format="text"
          value={office}
          options={offices}
          setValue={setOffice}
          placeholder="Select office"
          required
        />
      </div>
      <div className={styles["input-fields-container"]}>
        <PrimaryButton onClick={() => searchForNumbers()} isLoading={isLoading} />
      </div>
    </div>
  );
};

const PrimaryButton = (props) => {
  const {onClick, isLoading} = props;
  return (
    <button className={styles["search-btn"]} onClick={() => onClick()} disabled={isLoading}>
      {isLoading ? "Searching.." : "Search"}
    </button>
  );
};

const AvailableNumbers = ({companyId, numbers, office, onSuccess}) => {
  return (
    <div className={styles["available-numbers-container"]}>
      <h3>Available numbers</h3>
      <div className={styles["available-numbers"]}>
        {numbers.length &&
          numbers.map((number, index) => {
            return (
              <NumberInfo
                key={index}
                companyId={companyId}
                number={number}
                office={office}
                onSuccess={onSuccess}
              />
            );
          })}
      </div>
    </div>
  );
};

const NumberInfo = ({companyId, number, office, onSuccess}) => {
  return (
    <div className={styles["number-container"]}>
      <span>{number.friendly_name && number.friendly_name}</span>
      <div className={styles["number-container-icons"]}>
        {number.capabilities && number.capabilities.voice && (
          <Icon icon="small-call" className={styles["space"]} />
        )}
        {number.capabilities && number.capabilities.sms && (
          <Icon icon="sms" color="#727E8C" className={styles["space"]} />
        )}
        {number.capabilities && number.capabilities.mms && (
          <Icon icon="mms" className={styles["space"]} />
        )}
        <span className={styles["size"]}>{number.locality && number.locality}</span>
        <span className={styles["size"]}>{number.region && number.region}</span>

        <OutlineButton
          companyId={companyId}
          number={number}
          office={office}
          onSuccess={onSuccess}
        />
      </div>
    </div>
  );
};

const OutlineButton = ({companyId, number, office, onSuccess}) => {
  const [isLoading, setIsLoading] = useState(false);
  const buyNumber = async () => {
    try {
      setIsLoading(true);
      const res = await numberApi().buyNumber(
        companyId,
        number.phone_number && number.phone_number,
        office?.value
      );
      notify(SUCCESS, "You bought new number");
      setIsLoading(false);
      onSuccess?.();
    } catch (error) {
      setIsLoading(false);
      console.dir(error);
      notify(DANGER, "Problem with buying new number", error.response.data.reason);
    }
  };

  return (
    <button className={styles["outline-btn"]} onClick={() => buyNumber()} disabled={isLoading}>
      {isLoading ? "Processing.." : "Get number"}
    </button>
  );
};

export default withRedux(BuyNumber);
