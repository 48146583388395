import React, {useEffect, useState, useRef} from "react";
import moment from "moment";
import useOutsideAlerter from "@/custom-hooks/useOutsideAlerter";
import {IconButton, TextInput, DatePicker, TimePicker, Icon} from "UI";
import styles from "./FormDateTimeInput.module.css";

const FormDateTimeInput = (props) => {
  const {
    label,
    removeLabelElement,
    placeholder,
    name,
    form,
    defaultValue,
    minimal,
    className,
    disabled,
    minDate,
    containerClassName,
    validation,
    error,
    flag,
    hourLimit,
    showTimePicker = true,
    onChange,
    dropdownAlignRight,
  } = props;

  const setValue = (val) => {
    setVal(val);
    onChange && onChange(val);
  };

  const [value, setVal] = useState(defaultValue ? moment(defaultValue) : "");
  const [showPicker, setShowPicker] = useState(false);

  const [minTime, setMinTime] = useState(
    hourLimit &&
      (!defaultValue ||
        moment(defaultValue).format("DD MMM YYYY") === moment().format("DD MMM YYYY"))
      ? {
          hour: moment().get("hour"),
          minute: moment().get("minute"),
        }
      : {}
  );

  useEffect(() => {
    setVal(defaultValue ? moment(defaultValue) : "");
  }, [defaultValue]);
  const setDate = (val) => {
    const date = moment(val);
    const today = moment();
    if (value) {
      let hourToSet = moment(value).get("hour");
      let minuteToSet = moment(value).get("minute");
      if (hourLimit) {
        if (date.format("DD MMM YYYY") === today.format("DD MMM YYYY")) {
          hourToSet = today.get("hour");
          minuteToSet = today.get("minute");
          setMinTime({
            hour: hourToSet,
            minute: minuteToSet + 1,
          });
        } else {
          setMinTime(null);
        }
      }
      date.set("hour", hourToSet);
      date.set("minute", minuteToSet);
    } else if (hourLimit && date.format("DD MMM YYYY") === today.format("DD MMM YYYY")) {
      setMinTime({
        hour: today.get("hour"),
        minute: today.get("minute"),
      });
    }
    setValue(date);

    if (!showTimePicker) {
      setShowPicker(false);
    }
  };

  const setTime = (val) => {
    const vals = val.split(":");
    let date = moment();
    const today = moment();
    let hourToSet = parseInt(vals[0]);
    let minuteToSet = parseInt(vals[1]);
    if (value) {
      date = moment(value);
    }
    if (hourLimit && date.format("DD MMM YYYY") === today.format("DD MMM YYYY")) {
      setMinTime({
        hour: date.get("hour"),
        minute: date.get("minute"),
      });
      // hourToSet = date.get("hour")
      // minuteToSet = date.get("minute")
    }
    date.set("hour", hourToSet);
    date.set("minute", minuteToSet);
    setValue(date);
  };

  const pickerRef = useRef(null);
  useOutsideAlerter([pickerRef], showPicker, setShowPicker);

  let labelCSSStyle = "label";
  if (!label) {
    labelCSSStyle = "blank-label";
  }
  return (
    <>
      <div className={styles.backdrop} style={!showPicker ? {display: "none"} : {}} />
      <div className={containerClassName}>
        {!removeLabelElement && (
          <label className={styles[`${labelCSSStyle}`]}>
            {label}
            <span className={styles["flag"]}>{flag}</span>
            {validation && validation.required && <span className="text-danger">*</span>}
          </label>
        )}
        <div className={styles["input-box"]} onClick={() => setShowPicker((prev) => !prev)}>
          <input
            className="d-none"
            name={name}
            value={value ? value.format("DD MMM YYYY HH:mm") : ""}
            onChange={() => {}}
            ref={form(validation)}
            type="text"
            autoComplete="off"
          />
          <TextInput
            error={error}
            value={value ? value.format(`DD MMM YYYY${showTimePicker ? " HH:mm" : ""}`) : ""}
            onChange={() => {}}
            // defaultValue={value ? value.format("DD MMM YYYY HH:mm") : ""}
            className={className}
            disabled={disabled}
            placeholder={value ? "" : placeholder}
            form={form ? form(validation) : null}
            name={name}
            type="text"
            autoComplete="off"
          />
          {error && (
            <small style={{color: "#f40034", marginTop: "3px"}}>
              {typeof error == "object" ? error.message : error}
            </small>
          )}
          <div className={styles["selected-value"]}>
            {/*{value*/}
            {/*  ? minimal*/}
            {/*    ? value.format("l")*/}
            {/*    : value.format("DD MMM YYYY HH:mm")*/}
            {/*  : null}*/}
          </div>
          {value ? (
            <button
              onClick={(e) => {
                setValue("");
                e.stopPropagation();
              }}
              className={styles.remove}
            >
              &times;
            </button>
          ) : showTimePicker ? (
            <IconButton
              icon="close-nav"
              style={{
                position: "absolute",
                right: "20px",
                top: "15px",
                transform: "rotate(-90deg)",
              }}
            />
          ) : (
            <IconButton
              icon="calendar-icon"
              style={{
                position: "absolute",
                right: "15px",
                top: "10px",
              }}
            />
          )}
          {showPicker && (
            <div
              ref={pickerRef}
              className={styles["container"]}
              style={{right: dropdownAlignRight ? 0 : "auto"}}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={styles[`close-picker`]}>
                <Icon className={styles.close} icon="close" onClick={() => setShowPicker(false)} />
              </div>
              <div className={styles["input-container"]}>
                <DatePicker
                  removeBox
                  setValue={setDate}
                  value={[value && value.toDate()] || []}
                  showPicker={showPicker}
                  minDate={minDate}
                  month={value && value.toDate()}
                />
                <div className={styles.separator}></div>

                {showTimePicker && (
                  <TimePicker
                    removeBox
                    setValue={setTime}
                    value={value ? value.format("HH:mm") : hourLimit ? "" : "10:00"}
                    showPicker={showPicker}
                    name={name}
                    minTime={minTime}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FormDateTimeInput;
