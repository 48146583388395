import React, {useEffect, useState, useRef} from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useOutsideAlerter from "@/custom-hooks/useOutsideAlerter";
import useDebounce from "@/util/useDebounce";
import cn from "classnames";
import {Icon} from "UI";

import styles from "./MultiSelectDropdown.module.css";

const MultiSelectDropdown = ({
  options: propsOptions,
  onSelect,
  selected,
  placeholder,
  loadOptions,
  disabledInput,
  renderOption,
  disableSelectAll,
  optionsContainerClassName,
  className,
  name,
  onBlur,
  isOpen: propsIsOpen,
  setIsOpen: propsSetIsOpen,
  modalPartsRefs = [],
  disableMultiselect,
  onAddOption,
}) => {
  const filterRef = useRef(null);
  const filterBtnRef = useRef(null);
  const [isOpen, setIsOpen] = useState(propsIsOpen || false);
  const [options, setOptions] = useState(propsOptions || []);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const debounceSearch = useDebounce(search, 500);

  useOutsideAlerter([filterRef, filterBtnRef, ...modalPartsRefs], isOpen, setIsOpen);

  useEffect(() => {
    if (propsOptions) {
      setOptions(propsOptions);
    }
  }, [propsOptions]);

  useEffect(() => {
    if (propsSetIsOpen) {
      propsSetIsOpen(isOpen);
    }
  }, [isOpen]);

  const limit = 100;
  const [optionsCount, setOptionsCount] = useState(0);
  const [optionsOffset, setOptionsOffset] = useState(0);
  const optionsHasNextPage = optionsCount > options.length;
  const [optionsSentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: optionsHasNextPage,
    onLoadMore: async () => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        let newOffset = optionsOffset + limit;
        const data = await loadOptions({
          search: search,
          offset: newOffset,
          limit,
        });
        setOptionsOffset(newOffset);
        setOptions((options) => [...options, ...data.options]);
      } catch (e) {
        console.log("error", e);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleLoadOptions = async () => {
    try {
      setIsLoading(true);
      const data = await loadOptions({search});
      setOptions(data.options);
      setOptionsOffset(0);
      setOptionsCount(data.count);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (loadOptions && isOpen) {
      handleLoadOptions();
    }
  }, [isOpen]);

  useEffect(() => {
    handleLoadOptions();
  }, [debounceSearch]);

  const handleSelect = (code) => {
    if (code === "all") {
      onSelect(selected.length === options.length ? [] : options.map((item) => item.code));
    } else if (disableMultiselect) {
      if (selected[0] == code) {
        onSelect([]);
      } else {
        onSelect([code]);
      }
    } else {
      selected.includes(code)
        ? onSelect(selected.filter((item) => item !== code && item !== "all"))
        : onSelect(
            selected.length === options.length ? ["all", ...selected, code] : [...selected, code]
          );
    }
  };
  return (
    <div className={styles.container}>
      <div
        ref={filterBtnRef}
        onClick={() => {
          setIsOpen((state) => !state);
        }}
        className={styles.inputContainer}
      >
        <input
          placeholder={placeholder}
          value={
            selected.length && options?.length
              ? selected[0] === "all"
                ? "All"
                : selected
                    .map((sel) => options.find((option) => option.code === sel)?.name ?? [])
                    .join(", ")
              : ""
          }
          disabled
          className={cn(className, styles.inputField)}
          name={name}
          onBlur={onBlur}
        />
        <Icon
          icon={isOpen ? "arrow-up" : "arrow-down"}
          className={styles.iconContainer}
          color="#7E8A95"
        />
      </div>
      {isOpen && (
        <div ref={filterRef} className={cn(styles.optionsContainer, optionsContainerClassName)}>
          {!disabledInput && (
            <div className={styles.searchWrapper}>
              <Icon icon="search-dark" color="#7E8A95" className={styles.searchIcon} />
              <input
                className={styles.searchField}
                placeholder="Search to filter"
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    e.preventDefault();
                  }
                  if (onAddOption && e.key === "Enter") {
                    onAddOption(e.target.value);
                  }
                }}
                value={search}
              />
              <hr className={styles.hr} />
            </div>
          )}
          {disabledInput && !disableSelectAll && !disableMultiselect && (
            <>
              {renderOption ? (
                renderOption({name: "Select All", code: "all"}, selected, handleSelect)
              ) : (
                <div>
                  <div
                    className={styles.optionBox}
                    onClick={() => {
                      handleSelect("all");
                    }}
                  >
                    <span>Select All</span>
                    <div
                      className={
                        selected.length === options.length ? styles.activeRadio : styles.radio
                      }
                    />
                  </div>
                  <hr className={styles.hr} />
                </div>
              )}
            </>
          )}
          {search !== "" && options.length === 0 && (
            <div>
              <div className={styles.optionBox}>
                <span style={{fontStyle: "italic"}}>No match found</span>
              </div>
            </div>
          )}
          {options
            .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
            .map((option) => {
              return renderOption ? (
                renderOption(option, selected, handleSelect)
              ) : (
                <div key={option.code}>
                  <div
                    className={styles.optionBox}
                    onClick={() => {
                      handleSelect(option.code);
                    }}
                  >
                    <span>{option.name}</span>
                    <div
                      className={selected.includes(option.code) ? styles.activeRadio : styles.radio}
                    />
                  </div>
                </div>
              );
            })}
          {optionsHasNextPage && (
            <span ref={optionsSentryRef} className={styles.optionBox}>
              Loading...
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
