import React from "react";

import {TextAreaInput} from "UI";

import styles from "./FormTextAreaInput.module.css";

const FormTextAreaInput = (props) => {
  const {
    error,
    label,
    placeholder,
    name,
    defaultValue,
    form,
    validation,
    type,
    className,
    options,
    value,
  } = props;

  return (
    <div className={styles["input-box-container"]}>
      <label className={styles.label}>
        {label}
        {validation && validation.required && <span className="text-danger">*</span>}
      </label>
      <TextAreaInput
        defaultValue={defaultValue}
        form={form ? form(validation) : null}
        placeholder={placeholder}
        name={name}
        type={type}
        className={!error ? className : [`${className}, ${styles["danger"]}`]}
        options={options}
        value={value && value}
      />
      {error && (
        <small style={{color: "red", marginTop: "3px"}}>
          {typeof error == "object" ? JSON.stringify(error) : error}
        </small>
      )}
    </div>
  );
};

export default FormTextAreaInput;
