import React from "react";

import {SwitchButton} from "UI";

import styles from "./FormSwitch.module.css";
import {Controller} from "react-hook-form";
import cn from "classnames";

const FormSwitch = ({
  label,
  name,
  control,
  defaultValue = false,
  className,
  groupName,
  onChange,
}) => {
  return (
    <div className={cn(styles.input, className)}>
      {label && <label className={styles.label}>{label}</label>}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({onChange: onInternalChange, onBlur, value}) => {
          return (
            <SwitchButton
              checked={value}
              setChecked={(val) => {
                if (groupName && val) {
                  const allKeys = Object.keys(control.getValues()).filter((key) =>
                    key.includes(groupName)
                  );
                  for (const key of allKeys) {
                    if (key !== name) {
                      control.setValue(key, false);
                    }
                  }
                }
                onInternalChange(val);
                onChange && onChange(val);
              }}
              onBlur={onBlur}
            />
          );
        }}
      />
    </div>
  );
};

export default FormSwitch;
