//import moment from "moment";
import moment from "moment-timezone";
import {notify} from "UI";

export function getSafeDeep(object, path, undefinedValue = "") {
  let currentObject = object;
  const pathStrings = path.split(".");

  for (let pathString of pathStrings) {
    if (currentObject == null) return undefinedValue;
    currentObject = currentObject[pathString];
  }

  if (currentObject == null) {
    return undefinedValue;
  } else {
    return currentObject;
  }
}

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const renderDate = (date) => {
  if (date.split("T")[0] === moment().format("YYYY-MM-DD"))
    return "Today " + date.split("T")[1].substr(0, 5);
  else if (date.split("T")[0] === moment().subtract(1, "days").format("YYYY-MM-DD"))
    return "Yesterday";
  else return moment(date.split("T")[0]).format("MMMM DD");
};

export const setTimeZone = (time, time_zone) => {
  return moment()
    .set({
      h: time.split(":")[0],
      m: time.split(":")[1],
      s: time.split(":")[2],
    })
    .tz(time_zone, true)
    ?.toString();
};

export const uniqueArrayOfObjectsById = (arr) =>
  arr.reduce((unique, o) => {
    if (!unique.some((obj) => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  }, []);

export const percent = (percentToGet, num) => {
  return Math.round(((percentToGet / num) * 100 + Number.EPSILON) * 100) / 100;
};

export const renderTimeInHours = (sec) => {
  let hours = Math.floor(sec / 3600);

  let minutes = Math.floor(sec / 60);

  let seconds = sec - minutes * 60;

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }

  return `${hours}:${minutes}:${seconds}`;
};

export function formatCurrency(number) {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function timezoneConverter(time, timezone) {
  let userTime = moment.tz(time, timezone !== null ? timezone : moment.tz.guess())._d;
  return userTime;
}

// check whether an object is empty (=== {})
export function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export const handleErrors = (err, message, type, desc = "") => {
  const removeSuffixIfPresent = (inputString) => {
    //removes all dots followed by number on the end of string
    return inputString.replace(/\.\d+$/, "");
  };

  if (
    err?.response?.data?.error === "validationError" ||
    err?.response?.data?.error === "Problem in InCallLeadModal can't fetch lead data."
  ) {
    let backendErr = err?.response?.data?.reason;
    if (backendErr) {
      JSON.parse(backendErr).map((bE) => {
        bE.fields.map((field) => {
          return notify(type, `Field ${removeSuffixIfPresent(field)}`, bE.reason);
        });
      });
    }
  } else if (getSafeDeep(err, "response.data.reason")) {
    notify(type, getSafeDeep(err, "response.data.reason"), desc);
  } else {
    notify(type, message, desc);
  }
};

export const getBase64Image = (img) => {
  try {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    const dataURL = canvas.toDataURL("image/png");
    return dataURL;
  } catch (e) {
    return "";
  }
};

export const getCallStatus = (contactDate, followUps) => {
  if (!followUps?.length) return null;
  // Get difference between now and lastContactDate in minutes
  const diffFromNow = (new Date() - new Date(contactDate)) / (1000 * 60);
  // Find followup which is in between those minutes
  const res = followUps.filter(
    ({minutes_to, hours_to, days_to, minutes_from, hours_from, days_from}) => {
      const interval_to = minutes_to + (hours_to + days_to * 24) * 60;
      const interval_from = minutes_from + (hours_from + days_from * 24) * 60;
      return interval_from <= diffFromNow && interval_to > diffFromNow;
    }
  );

  if (!res.length) return null;

  //return the shortest interval, it has the highest priority
  return res.reduce((prev, curr) => {
    const prev_interval_from = prev.minutes_from + (prev.hours_from + prev.days_from * 24) * 60;
    const curr_interval_from = curr.minutes_from + (curr.hours_from + curr.days_from * 24) * 60;
    return curr_interval_from > prev_interval_from ? curr : prev;
  });
};

export const parseMovingAddress = (addressOrObj, city, state, zipcode, country_code) => {
  let address1 = addressOrObj;
  if (addressOrObj && typeof addressOrObj === "object") {
    ({address1, city, state, zipcode, country_code} = addressOrObj);
  }
  return [address1, city, state, zipcode, country_code].filter(Boolean).join(" ");
};

export const convertTime = (time) => {
  const hoursMinutesSeconds = moment.utc(time * 1000).format("HH:mm:ss");
  const minutesSeconds = moment.utc(time * 1000).format("mm:ss");
  const seconds = time;

  if (!hoursMinutesSeconds.startsWith("00:")) return hoursMinutesSeconds + " h";
  else if (!minutesSeconds.startsWith("00:")) return minutesSeconds + " min";
  else return seconds + " s";
};

export const validateEmail = (email) => {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validatePassword = (pw) => {
  let re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  return re.test(pw);
};

export const checkRequired = (value) => {
  if (value && value.length > 0) {
    return false;
  }
  return true;
};

export const calculateInventoryWeight = (inventory, metric = false) => {
  if (inventory?.floors) {
    let weight = 0;
    inventory.floors?.forEach((floor) => {
      floor.rooms?.forEach((room) => {
        const filteredItems = room.items?.filter((item) => !item.excluded);
        weight += filteredItems.reduce((total, item) => {
          let numericWeight = parseFloat(item.weight || 0).toFixed(2);
          if (isNaN(numericWeight)) {
            numericWeight = 0;
          }
          let numericQuantity = parseInt(item.quantity || 0);
          if (isNaN(numericQuantity)) {
            numericQuantity = 0;
          }
          return total + numericWeight * numericQuantity;
        }, 0);
      });
    });
    return metric ? weight.toFixed(2) : weight.toFixed(0);
  }
  return 0;
};

export const calculateInvetoryVolume = (inventory, metric = false) => {
  if (inventory?.floors) {
    let volume = 0;
    inventory.floors?.forEach((floor) => {
      floor.rooms?.forEach((room) => {
        const filteredItems = room.items?.filter((item) => !item.excluded);
        volume += filteredItems.reduce((total, item) => {
          let numericVolume = parseFloat(item.volume || 0).toFixed(2);
          if (isNaN(numericVolume)) {
            numericVolume = 0;
          }
          let numericQuantity = parseInt(item.quantity || 0);
          if (isNaN(numericQuantity)) {
            numericQuantity = 0;
          }
          return total + numericVolume * numericQuantity;
        }, 0);
      });
      return volume.toFixed(0);
    });
    return metric ? volume.toFixed(2) : volume.toFixed(0);
  }
  return 0;
};

export const calculateInvetoryNumberOfItems = (inventory) => {
  const {items, boxes} = inventory?.floors.reduce(
    (acc, floor) => {
      floor.rooms?.forEach((room) => {
        room.items?.forEach((item) => {
          if (!item.excluded) {
            acc[item.packing ? "boxes" : "items"] += item.quantity;
          }
        });
      });
      return acc;
    },
    {items: 0, boxes: 0}
  );
  return {items, boxes};
};

export const weightToImperial = (weight, isMetric) => {
  return isMetric ? weight * 2.20462262185 : weight;
};

export const weightToMetric = (weight, isMetric) => {
  return !isMetric ? weight * 0.45359237 : weight;
};

export const volumeToImperial = (volume, isMetric) => {
  return isMetric ? volume * 35.31467 : volume;
};

export const volumeToMetric = (volume, isMetric) => {
  return !isMetric ? volume * 0.028316832082557367 : volume;
};

export const distanceToImperial = (distance) => {
  return distance !== 0 ? parseFloat(distance / 1.609).toFixed(2) : distance;
};

export const distanceToMetric = (distance) => {
  return distance !== 0 ? parseFloat(distance * 1.609).toFixed(2) : distance;
};

export const calculateValuationPrice = (valuation, weight = 0, isMetric = false) => {
  let total = 0;
  if (valuation?.valuation_type !== 0) {
    if (valuation?.pricing_method == 0) {
      let roundup = parseFloat(valuation?.roundup);
      total = parseFloat(valuation?.value_per_pound) * weightToImperial(weight, isMetric);
      if (roundup) {
        total = Math.ceil(total / roundup) * roundup;
      }
      let minimum = Math.max(parseFloat(valuation?.minimum_cost), roundup);
      if (total < minimum) {
        total = minimum;
      }
    } else {
      total = valuation?.flat_cost;
    }
  }
  return parseFloat(total) ? parseFloat(total) : 0;
};

export const checkIfFromDropDown = (address) => {
  if (address?.origin && address?.origin != "") {
    if (address?.lat && address?.lng) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export const getNotificationDetails = (notification) => {
  switch (notification.msg_type) {
    case "call_missed":
      return {
        message: "Missed Call",
        color: "#f40034",
        name: "CALL",
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name}`, //TODO
        link: "/call-list",
      };
    case "sms_received":
      return {
        message: "New sms",
        color: "#914eff",
        name: "SMS",
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name} ${notification?.data?.sender}`, //TODO
        link: `${
          notification?.data?.lead_id ? "/chat?lead_id=" + notification?.data?.lead_id : "/chat"
        }`, //TODO
      };
    case "email_received":
      return {
        message: "Mail received",
        color: "#48d7ab",
        name: "EMAIL",
        sender: notification?.data?.sender || "Unknown sender",
        link: `/email?selected_email=${notification?.data?.email_id || 1}`, //TODO
      };
    case "call_missed_queue":
      return {
        message: "Missed Call",
        color: "#f40034",
        name: "CALL",
        sender: notification?.data?.caller || "Unknown caller",
        link: "/call-list",
      };
    case "email_sent_cadence":
      return {
        message: "Sent cadence email",
        color: "#48d7ab",
        name: "CADENCE",
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name}`, //TODO
        link: `/email?selected_email=${notification?.data?.email_id || 1}`, //TODO
      };
    case "sms_sent_cadence":
      return {
        message: "Sent cadence sms",
        color: "#48d7ab",
        name: "CADENCE",
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name}`, //TODO
        link: `${
          notification?.data?.lead_id ? "/chat?lead_id=" + notification?.data?.lead_id : "/chat"
        }`, //TODO
      };
    case "lead_status_changed":
      return {
        message: "Lead change status",
        color: "#f88118",
        name: "STATUS",
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name}`, //TODO
        link: `/leads/${notification?.data?.lead_id || ""}`, //TODO
      };
    case "lead_stage_changed":
      return {
        message: "Lead change stage",
        color: "#48d7ab",
        name: "STAGE",
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name}`, //TODO
        link: `/leads/${notification?.data?.lead_id || ""}`, //TODO
      };
    case "lead_uploaded_documents":
      return {
        message: "Lead uploaded new file",
        color: "#48d7ab",
        sender: "",
        link: `/leads/${notification?.data?.lead_id || ""}`,
      };
    case "customer_commented_inventory":
      return {
        message: "Customer left comment on inventory",
        color: "#914eff",
        name: "INVENTORY",
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name}`, //TODO
        link: `/leads/${notification?.data?.lead_id || ""}
          ?move_id=${notification?.data?.move_id || ""}
          &inventory_id=${notification?.data?.inventory_id || ""}`,
      };
    case "task_reminder":
      return {
        message: "Task reminder",
        color: notification?.data?.task_type_color || "red",
        name: `${notification?.data?.task_type_name} || "TASK"`.toUpperCase(),
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name}`, //TODO
        link: `/leads/${notification?.data?.lead_id || ""}`, //TODO
      };
    case "quote_booked":
      return {
        message: "Customer booked quote",
        color: "#48d7ab",
        name: "QUOTE",
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name}`, //TODO
        link: `/leads/${notification?.data?.lead_id || ""}`, //TODO
      };
    case "ai_lead_dispatch_cancelled":
      return {
        message: "Dispatch cancelled via AI chatbot",
        color: "#48d7ab",
        name: "DISPATCH",
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name}`, //TODO
        link: "",
      };
    case "ai_lead_dispatch_rescheduled":
      return {
        message: "Dispatch rescheduled via AI chatbot",
        color: "#48d7ab",
        name: "DISPATCH",
        sender: `${notification?.data?.lead_first_name} ${notification?.data?.lead_last_name}`, //TODO
        link: "",
      };
    default:
      return {
        message: "New notification",
        color: "#48d7ab",
        name: "",
        sender: "",
        link: "",
      };
  }
};

export const getTimestampDiff = (timestamp) => {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "seconds ago",
      m: "a minute ago",
      mm: "%d minutes ago",
      h: "an hour ago",
      hh: "%d hours ago",
      d: "a day ago",
      dd: "%d days ago",
      M: "a month ago",
      MM: "%d months ago",
      y: "a year ago",
      yy: "%d years ago",
    },
  });
  return moment.duration(moment(timestamp || new Date()).diff(moment())).humanize();
};

export const sizeConvert = (size) => {
  if (size < 1024) {
    return size + " bytes";
  } else if (size < 1024 * 1024) {
    let kilobytes = (size / 1024).toFixed(1);
    return kilobytes + " KB";
  } else if (size < 1024 * 1024 * 1024) {
    let megabytes = (size / (1024 * 1024)).toFixed(1);
    return megabytes + " MB";
  } else {
    let gigabytes = (size / (1024 * 1024 * 1024)).toFixed(1);
    return gigabytes + " GB";
  }
};
