import React, {forwardRef} from "react";
import cn from "classnames";
import {Icon} from "UI";

import styles from "./ControlButton.module.css";

export const ControlButton = forwardRef(function ControlButton(
  {children, icon, onClick, dark, className, id, as: RenderComponent = "button"},
  ref
) {
  if (!dark)
    return (
      <RenderComponent
        id={id}
        className={[`${styles.button} ${className}`]}
        onClick={onClick}
        ref={ref}
      >
        <div className={styles.text}>{children}</div>
        <div className={styles["icon-container"]}>{icon && <Icon icon={icon} />}</div>
      </RenderComponent>
    );
  return (
    <RenderComponent
      id={id}
      className={cn(styles["dark-mode-button"], className)}
      onClick={onClick}
    >
      <div className={styles.text}>{children}</div>
      <div className={styles["dark-icon-container"]}>{icon && <Icon icon={icon} />}</div>
    </RenderComponent>
  );
});

export const ControlPrimaryButton = forwardRef(function ControlPrimaryButton(
  {children, icon, onClick, ...props},
  ref
) {
  return (
    <button
      className={cn(styles.button, styles["button-primary"])}
      onClick={onClick}
      style={{marginRight: 12}}
      ref={ref}
      {...props}
    >
      <div className={styles.text}>{children}</div>
      <div className={cn(styles["icon-container"], styles["icon-container-primary"])}>
        {icon && <Icon icon={icon} />}
      </div>
    </button>
  );
});

export default ControlButton;
