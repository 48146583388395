import React from "react";
import cn from "classnames";
import styles from "./SaveButton.module.css";

const SaveButton = (props) => (
  <button
    className={cn(styles.button, props.className)}
    onClick={props.onClick}
    type="submit"
    disabled={!!props.disabled}
  >
    <span className={styles.text}>
      {props.children ? props.children : props.text ? props.text : "Save"}
    </span>
  </button>
);

export default SaveButton;
