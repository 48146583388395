import React from "react";
import moment from "moment";
import DayPicker from "react-day-picker";

import Head from "next/head";

import {IconButton} from "UI";

import styles from "./DatePicker.module.css";
import {DatePickerStyles} from "./DatePicker.styles";

const Navbar = (props) => {
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={props.removeBox ? {padding: "0 16px 0 16px"} : {padding: "16px"}}
    >
      <IconButton
        onClick={() => props.onPreviousClick()}
        icon="close-nav"
        style={{
          lineHeight: 0,
        }}
      />
      <p className={styles["date"]}>{moment(props.month).format("MMM YYYY")}</p>
      <IconButton
        onClick={() => props.onNextClick()}
        icon="close-nav"
        style={{
          lineHeight: 0,
          transform: "rotate(180deg)",
        }}
      />
    </div>
  );
};

const Caption = (_) => {
  return null;
};

const DatePicker = (props) => {
  const {
    showPicker,
    value,
    removeBox,
    isRange,
    disableGoToToday = false,
    minDate,
    maxDate,
    hideLabel,
    setValue,
    month,
  } = props;

  const createDisabledDays = () => {
    const currentDate = new Date();
    const futureYear = currentDate.getFullYear() + 1000;
    const FarFutureDate = new Date(futureYear, currentDate.getMonth(), currentDate.getDate());
    const tempMinDate = new Date();
    tempMinDate.setDate(minDate.getDate() - 1);

    var disabledDays = [];

    if (minDate && maxDate) {
      disabledDays[0] = {from: new Date(0), to: tempMinDate};
      disabledDays[1] = {from: maxDate, to: FarFutureDate};
    } else if (minDate) {
      disabledDays[0] = {from: new Date(0), to: tempMinDate};
    } else if (maxDate) {
      disabledDays[0] = {from: maxDate, to: FarFutureDate};
    }
    return disabledDays;
  };

  const past = {
    before: minDate,
  };

  const onDayClick = (val) => {
    if (minDate) {
      if (!moment(val).isBefore(moment(minDate))) {
        setValue(val);
      }
    } else if (maxDate) {
      if (moment(val).isBefore(moment(maxDate))) {
        setValue(val);
      }
    } else {
      setValue(val);
    }
  };
  return (
    <>
      <Head>
        <style>{DatePickerStyles}</style>
        {removeBox && (
          <style>{`
            .DayPicker{
              outline: none;
              background: transparent;
              height: auto;
              border-radius: 0;
              box-shadow: none;
            }
          `}</style>
        )}
      </Head>
      <div className={styles["input-box"]} style={!showPicker ? {display: "none"} : {}}>
        {!hideLabel && <p className={styles.title}>Pick Date</p>}
        <DayPicker
          firstDayOfWeek={1}
          disableGoToToday={disableGoToToday}
          onTodayButtonClick={() => {
            setValue(moment());
          }}
          todayButton={disableGoToToday ? false : "Go to today"}
          captionElement={<Caption />}
          navbarElement={<Navbar removeBox />}
          onDayClick={(val) => onDayClick(val)}
          selectedDays={value}
          modifiers={past}
          month={month}
          disabledDays={minDate || maxDate ? createDisabledDays() : undefined}
        />
        <div></div>
      </div>
    </>
  );
};

export default DatePicker;
